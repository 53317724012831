<template>
    <div class="overflow-y-auto mt-[70px]">
        <menu-bar 
            v-model="searchQuery" 
            v-model:page="currentPage" 
            :maxPage="maxPage" 
            @startFilter="filterAllStudents" 
            @openFilters="" 
            @addStudent="showNewStudentForm = true" 
        />

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[60px]">
            <div class="text-white">
                <span class="text-[28px] font-semibold">{{ formatNumber(allCashSum) }}</span> {{ $t('adminsBlock.line.admin_cash') }}
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ formatNumber(allInvestSum) }}</span> {{ $t('adminsBlock.line.admin_invest') }}
            </div>
        </div>

        <keep-alive>
        <div class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#303061] text-white">
                        <th class=" py-2 w-[5%] text-center font-medium text-sm"></th>
                        <th class=" py-2 w-[10%] text-start font-medium text-sm">{{ $t('adminsBlock.table.name') }}</th>
                        <th class=" py-2 w-[15%] text-end font-medium text-sm">{{ $t('adminsBlock.table.cash_balance') }}</th>
                        <th class=" py-2 w-[15%] text-end font-medium text-sm">{{ $t('adminsBlock.table.investment_balance') }}</th>
                        <th class=" w-[55%] py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody v-if="!loading">
                    <tr v-for="admin in allAdmins" class="border-b-[1px] border-[#303061]">
                        <td class="py-3 pl-4">
                            <div class="w-10 h-10 border-[#91CBE5]  rounded-full overflow-hidden">
                                <div class="h-full w-full flex justify-center items-center text-[#91CBE5] bg-[#91CBE5] bg-opacity-20">{{ admin.admin_name[0] + admin.admin_surname[0] }}</div>
                            </div>
                        </td>
                        <td class="text-[#91CBE5] py-3 text-[14px]">{{ admin.admin_name + ' ' + admin.admin_surname }}</td>
                        <td class="text-[#41BF74] py-3 text-end text-[14px]">{{ admin.cash_balance_state.toFixed(2) }}</td>
                        <td class="text-[#E4F94E] py-3 text-end text-[14px]">{{ admin.invest_balance_state.toFixed(2) }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        </keep-alive>
        <div v-if="loading" class="z-50 fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#de3a73] border-t-transparent absolute top-[50%] left-[50%] ml-[-160px] mt-[-90px]"></div>
        </div>
        <div class="w-full mt-[14px] flex items-center justify-center" v-else-if="allAdmins.length == 0">
            <p class="text-[#A3A3C2] text-[16px]">Адмінів не знайдено</p>
        </div>  
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getAllAdmins, getBalanceState } from '@/services/apiRequests'

    export default {
        setup() {
            const allAdmins = ref([])

            const loading = ref(false)

            const allCashSum = ref(0)
            const allInvestSum = ref(0)

            const formatNumber = (number) => {
                const parts = number.toFixed(2).toString().split('.');
                const integerPart = parts[0];
                const decimalPart = parts[1];

                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                const formattedNumber = formattedInteger + '.' + decimalPart;

                return formattedNumber;
            }

            const loadAdmins = async () => {
                const res = await getAllAdmins()
                for (const admin of res) {
                    const cashBalanceState = await getBalanceState(admin.cash_balance_id)
                    const investBalanceState = await getBalanceState(admin.invest_balance_id)
                    admin.cash_balance_state = cashBalanceState
                    admin.invest_balance_state = investBalanceState
                    
                    allCashSum.value += cashBalanceState
                    allInvestSum.value += investBalanceState

                    allAdmins.value.push(admin)
                }
            }

            onMounted(async () => {
                loading.value = true
                await loadAdmins()
                console.log(allAdmins.value)
                loading.value = false
            })

            return {
                allAdmins,
                loading,
                allCashSum,
                allInvestSum,
                formatNumber
            }
        }
    }

</script>