const ua = {
    sidebar: {
        dashboard: 'Показники',
        groups: 'Групи',
        trainers: 'Тренери',
        students: 'Учні',
        admins: 'Адміни',
        locations: 'Локації',
        calendar: 'Календар',
        finance: 'Фінанси',
        settings: 'Налаштування',
        incomes: 'Платежі',
        outlays: 'Нарахування',
        transactions: 'Транзакції',
        outlays_categories: 'Категорії витрат',
        club_outlays: 'Витрати',
    },

    dashboard: {
        balanceCards: {
            allIncomes: 'всі джерела',
            cashIncomes: 'готівка',
            cashlessIncomes: 'безготівка',
            investIncomes: 'інвестиції'
        },
        statisticCards: {
            averagePayment: 'середній платіж',
            coachesCash: 'готівка у тренерів',
            totalWriteoffs: 'сума списань',
            numberOfWriteoffs: 'кількість списань'
        },
        incomesBySources: 'ДОХОДИ ПО ДЖЕРЕЛАХ',
        incomesByGroups: 'ДОХОДИ ПО ГРУПАХ',
        allIncomes: 'всі доходи',
        outlaysTitle: 'ВИТРАТИ',
        earningsTitle: 'ПРИБУТОК',
        wholeYear: 'ЗА ВЕСЬ РІК',

        earnings: {
            revenue: 'дохід',
            earnings: 'прибуток',
            outlays: 'витрати',
        },
        statisticTitle: 'КІЛЬКІСНІ ПОКАЗНИКИ',
        statisticBlock: {
            trainers: 'тренери',
            groups: 'групи',
            students: 'учні'
        },
        trainerAnalytics: 'АНАЛІТИКА ПО ТРЕНЕРАХ',
        trainerAnalyticstTable: {
            photo: 'фото',
            trainer: 'тренер',
            groups: 'групи',
            players: 'гравці',
            totalCompletedTrainings: 'динаміка проведених тренувань',
            totalIncomesByTrainer: 'доходність по тренерах',
        },
        tips: {
            cashboxInfo: 'У блоці КАСА показані фактичні дані по наявним коштам у клубі станом на цей момент.',
            averagePayment: 'Середній платіж - сума оплат у поточному місяці поділена на кількість платежів',
            trainerCash: 'Готівка у тренерів - це сума готівки на руках у всіх тренерів',
            outlaysSum: 'Сума списань - показує, скільки коштів отримано у цьому місяці за проведені тренування, без врахування авансових платежів.',
            outlaysCount: 'Кількість списань показує: скільки учнів тренуалися та оплатили тренування у поточному місяці',
            incomesBySources: 'Діаграма ДОХОДИ ПО ДЖЕРЕЛАХ демонструє динаміку поступлення коштів по кожному джерелу, а також сумарну - по всім джерелам (червоним кольором)',
            circleDiagram: 'Кругова діаграма показує розподіл в процентах по видам доходів'
        }
    },

    groupsPage: {
        table: {
            groupName: 'Назва групи',
            trainer: 'Тренер',
            studentsCount: 'Кількість учнів',
        },

        groupCard: {
            players: 'Гравців',
            newGroupName: 'назва нової групи',
            yearRange: 'діапазон років народження учнів',
            selectTrainer: 'оберіть тренера зі списку',
            group: 'Група',
            trainer: 'Тренер',
            payable: 'платна',
            free: 'безоплатна',
            studentsManage: 'Управління учнями',
            infoAboutTrainer: 'інформація про тренера',
            trainerNotSelected: 'Не заданий',
            formTitle: 'Створення групи та призначення тренера',
            createButton: 'Створити',
            returnButton: 'Відмінити',
            selectTrainerPlaceholder: 'Оберіть тренера',
        },

        SelectStudentsForm: {
            saveGroupPlayers: 'Бажаєте зберегти состав групи',
            playersInClub: 'Гравців у групі',
            student: 'Учень',
            group: 'Група',
            age: 'Вік',
            from: 'від',
            to: 'до',
            page: 'Сторінка',
            studentsInGroup: 'Учні в групі',
            close: 'Закрити',
            trainerName: 'Ім\'я тренера',
            deleteGroup: 'Ви дійсно хочете видалити групу'
        }
    },

    shortMonthes: {
        jan: 'Січ',
        feb: 'Лют',
        mar: 'Бер',
        apr: 'Кві',
        may: 'Тра',
        jun: 'Чер',
        jul: 'Лип',
        aug: 'Сер',
        sep: 'Вер',
        oct: 'Жов',
        nov: 'Лис',
        dec: 'Гру'
    },

    fullMonthes: {
        jan: 'січень',
        feb: 'лютий',
        mar: 'березень',
        apr: 'квітень',
        may: 'травень',
        jun: 'червень',
        jul: 'липень',
        aug: 'серпень',
        sep: 'вересень',
        oct: 'жовтень',
        nov: 'листопад',
        dec: 'грудень'
    },

    menuBar: {
        addButton: 'Додати',
        searchPlaceholder: 'Пошук',
        page: 'Сторінка',
    },

    trainersBlock: {
        table: {
            trainerName: 'Тренер',
            groupsCount: 'Групи',
            playersCount: 'Кількість активних учнів',
            status: 'Статус'
        },
        line: {
            students: 'учнів',
            trainers: 'тренери', 
        },
        saveChanges: 'Бажаєте зберегти зміни у картці тренера',
        deleteTrainerConfirm: 'Ви дійсно хочете видалити тренера',

        trainerCard: {
            main: {
                main_placeholder: 'Лєо Мессі',
                surname: 'прізвище',
                surname_placeholder: 'Іваненко',
                name: 'ім\'я',
                name_placeholder: 'Іван',
                patronymic: 'по батькові',
                patronymic_placeholder: 'Іванович',
                phone: 'номер',
                phone_placeholder: '+380000000000',
                birthday: 'дата народження',
                email: 'email',
                email_placeholder: 'Email',
                nationality: 'національність',
                nationality_placeholder: 'Українець',
            },
            loginData: {
                title: 'ДАНІ ДЛЯ ВХОДУ',
                login: 'логін',
                login_placeholder: '000000000',
                password: 'пароль',
                password_placeholder: 'password',
            },
            parametersData: {
                title: 'ПАРАМЕТРИ',

                height: 'зріст',
                height_placeholder: '0.00',
                weight: 'вага',
                weight_placeholder: '0.00',
                chest_girth: 'об\'єм грудей',
                chest_girth_placeholder: '0.00',
                main_leg: 'нога',
                main_leg_placeholder: 'Ліва',
                leg_size: 'р. ноги',
                leg_size_placeholder: '0',
                club_start: 'старт',
                club_start_placeholder: '01.01.2020',
            },

            documentsData: {
                title: 'ДОКУМЕНТИ',

                eng_name: 'name',
                eng_name_placeholder: 'Ivan',
                eng_surname: 'surname',
                eng_surname_placeholder: 'Ivanenko',
                end_passport_date: 'дата закінчення паспорту',
                passport_number: 'паспорт',
                passport_number_placeholder: 'Номер паспорту',
                id_code: 'ID код',
                id_code_placeholder: '000000000000',
                ukrainian_passport_number: 'паспорт України',
                ukrainian_passport_number_placeholder: 'Номер паспорту',
                issue_institute: 'ким виданий',
                issue_institute_placeholder: 'орган, що видав',
                date_issue: 'дата видачі',
            },

            financeData: {
                title: 'ФІНАНСИ',

                student_balance: 'учнівський баланс',
                service_balance: 'сервісний баланс',
                payment: 'оплати',
                search: 'переглянути',
                transactions: 'транзакції',
                no_payments: 'немає оплат',
                no_transactions: 'немає транзакцій',
            }
        }
    },

    studentBlock: {
        table: {
            name: 'Ім\'я',
            group: 'Група',
            phone: 'Телефон',
            balance: 'Баланс',
            status: 'Статус',
        },

        studentCard: {
            main: {
                group: 'група',
                age: 'вік',
                amplua: 'амплуа',
                number: 'номер',
                main_placeholder: 'Лєо Мессі',
                surname: 'прізвище',
                surname_placeholder: 'Іваненко',
                name: 'ім\'я',
                name_placeholder: 'Іван',
                patronymic: 'по батькові',
                patronymic_placeholder: 'Іванович',
                phone: 'номер',
                phone_placeholder: '+380000000000',
                birthday: 'дата народження',
                email: 'email',
                email_placeholder: 'Email',
                nationality: 'національність',
                nationality_placeholder: 'Українець',
            },
            parametersData: {
                title: 'ПАРАМЕТРИ',

                login: 'логін',
                login_placeholder: '000000000',
                password: 'пароль',
                password_placeholder: 'password',

                height: 'зріст',
                height_placeholder: '0.00',
                weight: 'вага',
                weight_placeholder: '0.00',
                chest_girth: 'об\'єм грудей',
                chest_girth_placeholder: '0.00',
                main_leg: 'нога',
                main_leg_placeholder: 'Ліва',
                leg_size: 'р. ноги',
                leg_size_placeholder: '0',
                club_start: 'старт в клубі',
                club_start_placeholder: '01.01.2020',
                amplua: 'амплуа',
                amplua_placeholder: 'Нападник',
                player_number: 'номер гравця',
                player_number_placeholder: '0',
            },

            documentsData: {
                title: 'ДОКУМЕНТИ',

                eng_name: 'name',
                eng_name_placeholder: 'Ivan',
                eng_surname: 'surname',
                eng_surname_placeholder: 'Ivanenko',
                end_passport_date: 'дата закінчення паспорту',
                passport_number: 'паспорт',
                passport_number_placeholder: 'Номер паспорту',
                id_code: 'ID код',
                id_code_placeholder: '000000000000',
                ukrainian_passport_number: 'паспорт України',
                ukrainian_passport_number_placeholder: 'Номер паспорту',
                issue_institute: 'ким виданий',
                issue_institute_placeholder: 'орган, що видав',
                date_issue: 'дата видачі',
                attorney: 'Довіреність',
                documents_photo: 'Фото для документів',
                patron_person: 'відповідальна особа',
                patron_person_number: 'Телефон',
                patron_person_name: 'Ім\'я та прізвище',
            },

            statisticsData: {
                title: 'СТАТИСТИКА',

                count_of_trainings: 'тренувань',
                count_of_attendings: 'відвідувань',

                attend_training: 'тренувався',
                dont_attend_training: 'пропустив',
                planed_training: 'заплановано',
            },

            financeData: {
                title: 'ФІНАНСИ',

                current_balance: 'поточний баланс',
                payment: 'оплати',
                outlays: 'списання',
                search: 'переглянути',
                transactions: 'транзакції',
                no_payments: 'немає оплат',
                no_transactions: 'немає транзакцій',
            }

        },

        deleteStudent: 'Ви дійсно хочете видалити учня',
        studentsNotFound: 'Учнів не знайдено',
    },

    adminsBlock: {
        table: {
            name: 'Ім\'я',
            cash_balance: 'Готівковий баланс',
            investment_balance: 'Інвестиційний баланс',
        },
        line: {
            admin_cash: 'готівки у адмінів',
            admin_invest: 'інвестицій у адмінів'
        }
    },

    locationsBlock: {
        table: {
            location_name: 'Назва та адреса локації',
            short_name: 'Коротка назва',
            description: 'Опис',
        },

        line: {
            locations: 'локацій',
        },

        locationCard: {
            name: 'назва локації',
            short_name: 'коротка назва локації',
            address: 'адреса локації',
            map_url: 'посилання на карту',
            description: 'опис локації',
            photo: 'фото локації',
            newLocationTitle: 'Створення локації для подій',
            photoUploadPrompt: 'Завантажте фото локації',
        },

        locationsNotFound: 'Локацій не знайдено',
        deleteLocationConfirm: 'Ви дійсно хочете видалити локацію'
    },

    calendarBlock: {
        fullTrainingCard: {
            comment: 'Коментар до тренування',
            showInMap: 'Показати на карті',
        },

        eventTypes: {
            training: 'Тренування',
            match: 'Змагання',
            gym: 'Спортзал',
        },
        
        trainingForm: {
            notSelect: 'Не задано',
            recursiveTrainings: 'Повторюваність тренування',
            group: 'група',
            location: 'локація',
            comment: 'Коментар до тренування',
        },

        saveChanges: 'Бажаєте зберегти зміни у тренуванні',
    },

    paymentsBlock: {
        buttons: {
            getPaymentByStudent: 'Прийняти кошти',
            getInvest: 'Додати інвестицію',
            getPayment: 'Додати надходження',
            filters: 'Фільтри',
        },

        table: {
            receiver: 'Отримувач',
            receiverStatus: 'Статус отримувача',
            transactionType: 'Тип транзакції',
            summ: 'Сума',
            date: 'Дата',
            time: 'Час',
        },

        receiverStatuses: {
            student: 'Учень',
            trainer: 'Тренер',
            service: 'Сервіс',  
        },

        transactionTypes: {
            virtual: 'Віртуальна',
            real: 'Реальна',
        },

        row: {
            balanceName: 'Назва балансу',
            balanceID: 'ID балансу',
            transactionComment: 'Коментар до транзакції',
            transactionID: 'ID транзакції',
            embedTransaction: 'Пов\'язана транзакція',
            additionalFiles: 'Прикріплені файли',
        },

        addPaymentByStudentForm: {
            title: 'Прийняти платіж',
            addToServiceBalance: 'занести на сервісний рахунок',
            studentBalance: 'баланс учня',
            transactionSumm: 'сума транзакції',
            registrationDate: 'дата транзакції',
            additionalFiles: 'Прикріплені файли',
            receiveButton: 'Прийняти',
        },

        addInvestForm: {
            title: 'Додати інвестицію',
            investSumm: 'сума інвестиції',
            comment: 'коментар до транзакції',
            additionalFiles: 'Прикріплені файли',
            receiveButton: 'Прийняти',
        },

        getPayment: {
            title: 'Додати надходження',
            addToServiceBalance: 'занести на сервісний рахунок',
            transactionSumm: 'сума транзакції',
            transactionComment: 'коментар до транзакції',
            additionalFiles: 'Додаткові файли',
            receiveButton: 'Прийняти', 
        },

        filtersForm: {
            title: 'Фільтри надходжень',
            receiverStatus: 'статус отримувача',
            transactionType: 'тип транзакції',
            transactionOrigin: 'джерело транзакції',
            transactionAmount: 'сума транзакції',
            from: 'Від',
            to: 'До',
            transactionDate: 'дата транзакції',
            applyButton: 'Застосувати',
            exportExcel: 'Експортувати в Excel',
        }
    },

    outlaysBlock: {
        table: {
            sender: 'Відправник',
            senderStatus: 'Статус відправника',
            transactioType: 'Тип транзакції',
            amount: 'Сума',
            date: 'Дата',
            time: 'Час',
        },

        mainBar: {
            page: 'Сторінка',
            filters: 'Фільтри',
        },

        filtersForm: {
            title: 'Фільтри списань',
            receiverStatus: 'статус отримувача',
            transactionType: 'тип транзакції',
            transactionOrigin: 'джерело транзакції',
            transactionAmount: 'сума транзакції',
            from: 'Від',
            to: 'До',
            transactionDate: 'дата транзакції',
            applyButton: 'Застосувати',
        },

        row: {
            balanceName: 'Назва балансу',
            balanceID: 'ID балансу',
            transactionComment: 'Коментар до транзакції',
            transactionID: 'ID транзакції', 
        },
    },

    transactionBlock: {
        table: {
            date: 'Дата',
            sender: 'Відправник', 
            receiver: 'Отримувач', 
            amount: 'Сума',
        },
        mainBar: {
            page: 'Сторінка',
            filters: 'Фільтри',
            createTxButton: 'Створити транзакцію',
        },
        filters: {

        },
        newTransactionForm: {
            title: 'Створення транзакції',
            sender: 'з балансу',
            receiver: 'на баланс',
            amount: 'сума транзакції',
            comment: 'коментар',
            additionalFiles: 'Прикріплені файли',
            createButton: 'Прийняти',
        }
    },

    outlaysCategoriesBlock: {
        table: {
            category: 'Категорія',
            subcategory: 'Підкатегорія',
            monthAmount: 'Сума за місяць',
        },
        newCategoryPlaceholder: 'Нова категорія',
        newSubcategoryPlaceholder: 'Нова підкатегорія',
        allChangesSaved: 'Всі зміни збережено',
        changesSaving: 'Збереження змін',
    },

    settingsBlock: {
        allInfo: {
            title: 'Загальна інформація',
            uaClubName: 'Назва клубу українською',
            engClubName: 'Назва клубу англійською',
            creationDate: 'Дата створення',
            legalAddress: 'Юридична адреса',
            postAddress: 'Поштова адреса',
            mapUrl: 'Посилання на карту',
        },
        contacts: {
            title: 'Контакти',
            mainPhone: 'Основний телефон',
            secondPhone: 'Додатковий телефон',
            officialMail: 'Офіційний email',
            facebookPage: 'Facebook сторінка',
            webPage: 'Веб сторінка',
            instagramPage: 'Instagram сторінка',
        },
        bankBlock: {
            title: 'Банківські реквізити',
            bankName: 'Назва банку',
            receiverCode: 'Код отримувача',
            receiverAccount: 'Рахунок отримувача',
            paymentSystemIntegration: 'Інтеграція з платіжною системою',
            ERDPOU: 'Код ЄДРПОУ',
        }
    },

    clubOutlays: {
        table: {
            date: 'Дата',
            category: 'Категорія',
            subcategory: 'Підкатегорія',
            amount: 'Сума',
            comment: 'Коментар',
        },
        newOutlayForm: {
            title: 'Створення витрати',
            date: 'Дата',
            category: 'Категорія',
            subcategory: 'Підкатегорія',
            amount: 'Сума',
            comment: 'Коментар',
            additionalFiles: 'Прикріплені файли',
            createButton: 'Створити',
        }
    },

    userStatuses: {
        active: 'Активний',
        debtor: 'Боржник',
        achive: 'Архівний'
    },

    add: 'Додати',
    see: 'Переглянути',
    club_name: 'ФК МИНАЙ',
    noData: 'Немає даних',
    yes: 'Так',
    no: 'Ні',
    close: 'Закрити',
    change: 'Змінити',
    delete: 'Видалити',
    download: 'Завантажити',
    changesWillBeLoss: 'Ваші зміни будуть втрачені якщо ви не збережете їх.',
    save: 'Зберегти',
    dontSave: 'Не зберігати',
    cancel: 'Відмінити',
}

export default ua