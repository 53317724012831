<template>
    <div @click="processExit" style="background-color: rgba(171, 211, 236, .01)" class="backdrop-blur-[10px] fixed w-full h-[130%] top-[-70px] -mt-2 z-20"></div>
    <exit-confirm-form
        v-if="exitConfirmFlag && loadedData"
        :alertText="'Бажаєте зберегти зміни у картці групи ' + groupName"
        class="absolute top-[50%] ml-[110px] z-40 w-[260px] left-[50%] translate-x-[-50%] translate-y-[-50%]"

        @returnToCurrent="exitConfirmFlag = false"
        @dontSave="$emit('close')"
        @doSave="updateGroupVM()"
    />
    <select-students-form 
        v-if="selectStudentsFlag"
        :group="group"
        :trainer="trainer"
        class="z-40"
        @close="selectStudentsFlag = false; updateGroup()"
    />
    <div v-if="!loadedData" class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#de3a73] border-t-transparent absolute top-[50%] left-[50%] ml-[90px] z-40"></div>
    <div v-if="!exitConfirmFlag && loadedData && !selectStudentsFlag" class="z-40">
        <!-- <div class="absolute top-[50%] left-[50%] ml-[10px] mt-[-18px] z-50">
            <coach-selection-form 
                class="h-[340px] mb-2 z-50"
                v-if="selectNewTrainerFlag" 
                @selectTrainer="trainer => selectNewTrainer(trainer)"
            />
        </div> -->
        <!-- <div class="bg-[#91CBE5] rounded-[10px] w-[250px] h-[380px] absolute top-[50%] left-[50%] ml-[110px] translate-x-[-50%] translate-y-[-50%] z-40">

            <div class="h-[120px] mt-2 overflow-hidden w-full flex justify-center">
                <img v-if="trainer?.photo" :src="trainerPicture" class="h-[150px] relative">
                <img v-else src="@/assets/student_photo_placeholder.svg">
            </div>
            <div class="bg-[#142552] w-full py-1  z-30 text-white">
                <div class="font-medium text-[30px] mt-[-7px] text-center">{{ groupName.length > 0 ? groupName : 'Група' }}</div>
                <div class="text-center">{{ trainer?.name || trainer?.surname ? trainerFullName : 'Ім\'я тренера'}}</div>
            </div>

            <div class="mt-[10px]">
                
                <div class="flex w-[90%] justify-between mx-auto">                
                    <div class="flex justify-between items-center align-middle">
                        <div class="w-[50%]">
                            <input v-model="groupName" class="text-[#142552] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="U-2012">
                        </div>
                        <div class="text-[#142552] font-semibold  opacity-90 text-[13px]">
                            Назва групи
                        </div>
                    </div>
                </div>

                <div class="flex mt-2 w-[90%] justify-between mx-auto">                
                    <div class="flex justify-between items-center align-middle">
                        <div class="w-[50%]">
                            <input v-model="groupPeriods" class="text-[#142552] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="2011-2012">
                        </div>
                        <div class="text-[#142552] font-semibold ml-[17px] opacity-90 text-[13px]">
                            Періоди
                        </div>
                    </div>
                </div>
          
                <div class="flex w-[90%] mt-2 mx-auto justify-between items-center align-middle">
                    <div class="text-[#142552] font-semibold opacity-90 text-start text-[13px]">
                        Кількість учнів
                    </div>
                    <div class="flex items-center align-middle justify-end w-[50%] text-[#142552] font-semibold text-[13px] text-end outline-none bg-transparent border-none truncate">
                        <div>{{ studentsCount }}</div>
                        <img src="@/assets/icons/students_icon_dark.svg" class="fill-[#142552] w-[18px] mt-[2px] ml-[5px]">
                    </div>
                </div>

                <div @click="selectNewTrainerFlag = true" class="w-[90%] bg-[#142552] text-white py-1 text-center text-[13px] mt-[10px] cursor-pointer select-none mx-auto rounded-md">
                    Змінити тренера
                </div>
                <div @click="selectStudentsFlag = true" class="w-[90%] bg-[#142552] text-white py-1 text-center text-[13px] mt-[10px] cursor-pointer select-none mx-auto rounded-md">
                    Управління учнями
                </div>
            </div>
        </div> -->

        <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="border-[1px] border-[#8C8C8C33] bg-[#11204D] pt-[23px] rounded-[10px] px-[15px] w-[291px] h-[736px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] z-50">
            <div class="flex justify-center mb-[15px]"><img src="../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center px-[20px] text-[24px] text-[#fff] leading-[19px] mb-[10px]">{{ groupName }}</div>
            <div class="font-light text-center px-[20px] text-[16px] text-[#fff] leading-[19px] mb-[10px]">({{ $t('groupsPage.groupCard.players') }} - {{ studentsCount }})</div>

            <div class="w-full">
                <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                    {{ $t('groupsPage.groupCard.newGroupName') }}
                </div>
                <div class="mt-[3px]">
                    <input 
                        v-model="groupName"
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                        type="text" 
                        name="name"
                    >
                </div>
            </div>

            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                    {{ $t('groupsPage.groupCard.yearRange') }}
                </div>
                <div class="mt-[3px]">
                    <input 
                        v-model="groupPeriods"
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                        type="text" 
                        name="name"
                    >
                </div>
            </div>

            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                    {{ $t('groupsPage.groupCard.selectTrainer') }}
                </div>
                <select :value="trainerId" @change="e => selectNewTrainer(e.target.value)" class="mt-[3px] w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[5.5px] pl-[5px] outline-none" name="to_balance">
                    <option class="bg-[#142552] text-[#fff]" value="" disabled>Виберіть тренера</option>
                    <option v-for="trainer in trainers" :value="trainer.trainer_id" class="bg-[#142552] text-[#fff]">{{ trainer.name + ' ' + trainer.surname }}</option>
                </select>
            </div>

            <div class="w-full mt-[20px] flex items-center justify-between">
                <div class="text-[#8C8C8C] text-center font-medium relative top-[5px] text-[14px]">
                    {{ $t('groupsPage.groupCard.group') }} 
                    <span v-if="groupPaymentStatusBool" class="text-[#41BF74]">{{ $t('groupsPage.groupCard.payable') }}</span>
                    <span v-else class="text-[#FA1367]">{{ $t('groupsPage.groupCard.free') }}</span>
                </div>
                <div>
                    <IphoneSwitch :isOnValue="groupPaymentStatusBool" @change="changeGroupPaymentStatus"/>
                </div>
            </div>

            <div class="relative mx-auto h-[234px] border-[1px] border-[#9E9E9E] mt-[20px] rounded-[6px]">
                <div v-if="selectTrainerFlag" class="w-full h-full relative">
                    <div class="flex justify-center items-center h-full">
                        <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                            {{ $t('groupsPage.groupCard.infoAboutTrainer') }}
                        </div>
                    </div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] rotate-[45deg]"></div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] right-0 rotate-[45deg]"></div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] left-0 rotate-[-45deg]"></div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] right-0 rotate-[-45deg]"></div>
                </div>

                <div v-else class="absolute w-full bottom-0">
                    <div class="h-[150px] w-full flex justify-center -z-10">
                        <img :key="trainerPictureKey" :src="trainerPicture" @error="$event.target.src = require('@/assets/photo-placeholder-big.png')" class="-z-10 relative ">
                    </div>
                    <div class="bg-[#F10265] w-full py-1 mt-[-20px] z-20 text-white">
                        <div class="font-medium text-[23px] text-center">{{ trainer.name ? trainer.name + ' ' + trainer.surname : "Тренер" }}</div>
                        <div class="font-medium text-[23px] relative bottom-[3px] text-center">{{ groupName.length > 0 ? groupName : 'Група' }}</div>
                        <div class="text-center">({{ groupPeriods ? groupPeriods : "? - ?" }})</div>
                    </div>
                </div>
            </div>

            <button @click="selectStudentsFlag = true" class="w-full rounded-[6px] bg-[#41BF74] text-[13px] text-[#11204D] py-[6px] mt-[20px]">
                {{ $t('groupsPage.groupCard.studentsManage') }}
            </button>
        </div>
    </div>


</template>

<script>
    import { ref, onMounted } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { getTrainerById, getGroupById, getStudentsCountByGroupId, updateGroupRequest, getAllTrainers } from '@/services/apiRequests'

    import DataInput from '../ui/DataInput.vue'
    import CoachSelectionForm from '../ui/CoachSelectionForm.vue'
    import SelectStudentsForm from './SelectStudentsForm.vue'
    import IphoneSwitch from '../ui/IphoneSwitch.vue'

    import ExitConfirmForm from '../ui/ExitConfirmForm.vue'

    export default {
        name: 'group-full-card',

        props: {
            group: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const selectStudentsFlag = ref(false)
            
            const groupClone = ref(Object.assign({}, props.group))
            
            const groupName = ref(groupClone.value.name)
            const groupPeriods = ref(groupClone.value.year_range)
            
            const trainer = ref({})
            const trainerPicture = ref('')

            let bufferGroupName = ''
            let bufferGroupPeriods = ''
            let bufferGroupPaymentStatusText = ''
            let bufferGroupTrainer = {}

            const trainerFullName = ref('')

            const trainers = ref([])
            const trainerId = ref('')
            const trainerPictureKey = ref(uuid.v4())

            const groupPaymentStatusBool = ref(props.group.payment_status && props.group.payment_status == 'paid' ? true : false)
            const groupPaymentStatusText = ref(props.group.payment_status && props.group.payment_status == 'paid' ? 'paid' : 'free')

            const loadedData = ref(false)

            const updateGroupVM = () => {
                console.log('request trainer', trainer.value)
                updateGroupRequest(
                    props.group.group_id,
                    groupName.value,
                    groupPeriods.value,
                    trainer.value.trainer_id,
                    groupPaymentStatusText.value
                ).then(res => {
                    console.log(res)
                    if (res.status === 200) {
                        emit('close')
                        emit('updateGroup')
                    }
                })
            }

            const processExit = () => {
                if ((bufferGroupName !== groupName.value || bufferGroupPeriods !== groupPeriods.value || bufferGroupTrainer?.trainer_id !== trainer.value?.trainer_id || bufferGroupPaymentStatusText !== groupPaymentStatusText.value) && loadedData.value) {
                    exitConfirmFlag.value = true
                } else {
                    emit('close')
                }
            }

            const selectNewTrainerFlag = ref(false)

            const exitConfirmFlag = ref(false)

            const selectNewTrainer = (newTrainerId) => {
                const newTrainer = trainers.value.find(trainer => trainer.trainer_id == newTrainerId)
                trainer.value = newTrainer
                trainerPicture.value = process.env.VUE_APP_API_URL + 'static/' + newTrainer.photo
                selectNewTrainerFlag.value = false
                trainerFullName.value = trainer.value.name + ' ' + trainer.value.surname
                trainerPictureKey.value = uuid.v4()
            }
            
            const studentsCount = ref(0)

            const updateGroup = async () => {
                const res = await getGroupById(props.group.group_id)
                groupClone.value = res.data
                const studentsCountRes = await getStudentsCountByGroupId(props.group.group_id)
                studentsCount.value = studentsCountRes.data
            }

            onMounted(async () => {
                try {
                    const res = await getTrainerById(props.group.trainer_id)
                    trainer.value = res.data
                } catch (e) {
                    trainer.value = null
                }
                const studentsCountRes = await getStudentsCountByGroupId(props.group.group_id)
                studentsCount.value = studentsCountRes.data
                trainerFullName.value = trainer.value?.name + ' ' + trainer.value?.surname
                trainerPicture.value = process.env.VUE_APP_API_URL + 'static/' + trainer.value?.photo
                trainerId.value = trainer.value?.trainer_id
            
                bufferGroupName = groupName.value
                bufferGroupPeriods = groupPeriods.value
                bufferGroupTrainer = Object.assign({}, trainer.value)
                bufferGroupPaymentStatusText = groupPaymentStatusText.value + ''

                const response = await getAllTrainers()
                response.data.forEach(trainer => {
                    trainers.value.push({
                        trainer_id: trainer.trainer_id,
                        name: trainer.name,
                        surname: trainer.surname,
                        photo: trainer.photo
                    })
                })

                loadedData.value = true
            })

            const changeGroupPaymentStatus = () => {
                groupPaymentStatusBool.value = !groupPaymentStatusBool.value
                if (groupPaymentStatusBool.value) {
                    groupPaymentStatusText.value = 'paid'
                } else {
                    groupPaymentStatusText.value = 'free'
                }
            }

            return {
                selectStudentsFlag,
                groupName,
                groupPeriods,
                trainer,
                updateGroup,
                studentsCount,
                trainerPicture,
                selectNewTrainer,
                selectNewTrainerFlag,
                trainerFullName,
                exitConfirmFlag,
                processExit,
                updateGroupVM,
                loadedData,
                trainers,
                trainerId,
                trainerPictureKey,
                groupPaymentStatusBool,
                groupPaymentStatusText,
                changeGroupPaymentStatus
            }
        },

        components: {
            DataInput,
            CoachSelectionForm,
            SelectStudentsForm,
            ExitConfirmForm,
            IphoneSwitch
        }
    }
</script>
