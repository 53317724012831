<template>
    <div @click="$emit('close')" style="background-color: rgba(17, 32, 77, .35)" class=" fixed w-full z-40 h-full -mt-2"></div>
    <div class="absolute top-[50%] left-[50%] translate-x-[-50%] border-[1px] border-[#9E9E9E]  translate-y-[-50%] bg-[#142552] z-50 ml-[100px] p-[10px] rounded-lg">
        <div class="flex justify-center overflow-hidden h-[65vh] w-[55vw]">
            <img
                :src="imageUrl"
                :alt="altText"
                ref="image"
                class="object-contain"
                @mousedown.prevent="startDrag"
                @mousemove.prevent="drag"
                @mouseup.prevent="endDrag"
                @wheel.prevent="zoom"
            />
        </div>
      <div v-if="!imageUrl.startsWith('http')" class="mt-[5px] flex justify-center">
        <button class="bg-[#001A72] hover:bg-opacity-75 text-white px-4 py-2 rounded shadow mx-2" @click="download">
          Завантажити
        </button>
        <button class="bg-[#F10265] hover:bg-opacity-75 text-white px-4 py-2 rounded shadow mx-2" @click="$emit('delete')">
          Видалити
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageUrl: {
        type: String,
        required: true,
      },
      altText: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        dragging: false,
        dragStart: { x: 0, y: 0 },
        imageStart: { x: 0, y: 0 },
        scale: 1,
      };
    },
    methods: {
      startDrag(event) {
        this.dragging = true;
        this.dragStart = { x: event.clientX, y: event.clientY };
        this.imageStart = { x: this.$refs.image.offsetLeft, y: this.$refs.image.offsetTop };
      },
      drag(event) {
        if (this.dragging) {
          const dx = event.clientX - this.dragStart.x;
          const dy = event.clientY - this.dragStart.y;
          this.$refs.image.style.left = `${this.imageStart.x + dx}px`;
          this.$refs.image.style.top = `${this.imageStart.y + dy}px`;
        }
      },
      endDrag() {
        this.dragging = false;
      },
      zoom(event) {
        const scaleDelta = event.deltaY > 0 ? -0.1 : 0.1;
        this.scale += scaleDelta;
        this.scale = Math.max(0.1, this.scale);
        this.$refs.image.style.transform = `scale(${this.scale})`;
      },
      download() {
        const link = document.createElement('a');
        link.href = this.imageUrl;
        link.download = this.altText;
        link.click();
      },
    },
  };
  </script>
  