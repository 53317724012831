<template>
    <div class="absolute shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] bg-[#11204D] top-[50%] left-[50%] z-[150] p-4 rounded-[10px] mt-[-20px] w-[250px]">
        <div class="text-center mb-3 text-[#ABD3EC]">{{ $t('groupsPage.SelectStudentsForm.deleteGroup') }} {{ group.name }}?</div>
        <div class="flex justify-between">
            <button @click="deleteGroup" class="bg-[#DE3A73] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">{{ $t('yes') }}</button>
            <button @click="$emit('close')" class="bg-[#ABD3EC] px-4 py-1 rounded-[10px]">{{ $t('no') }}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-group-form',

        props: {
            group: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const deleteGroup = () => {
                emit('acceptDeleteGroup', props.group)
                emit('close')
            }

            return {
                deleteGroup
            }
        }
    }
</script>
