<template>
    <button class="switch-button" @click="$emit('change', !isOnValue)" :class="{ 'switch-on': isOnValue, 'switch-off' : !isOnValue }">
      <div class="switch-slider"></div>
    </button>
  </template>
  
<script>
  import { ref } from 'vue';
  
  export default {
    name: 'SwitchButton',

    props: {
        isOnValue: {
            type: Boolean,
            required: true
        },

        width: {
            type: String,
            default: '50px'
        },
        
        height: {
            type: String,
            default: '35px'
        }
    }
  };
</script>
  
<style scoped>
    .switch-button {
        position: relative;
        width: 46px;
        height: 22px;
        border-radius: 15px;
        border: solid 1px #8C8C8C33;
        cursor: pointer;
        outline: none;
    }

    .switch-on {
        background-color: #8C8C8C33;
    }

    .switch-off {
        background-color: #8C8C8C33;
    }

    .switch-slider {
        position: absolute;
        top: 0px;
        margin-top: 1px;
        left: 1.8px;
        width: 18px;
        height: 18px;
        border-radius: 13px;
        background-color: #fff;
        transition: transform 0.3s ease;
    }

    .switch-on .switch-slider {
        transform: translateX(22px);
    }
</style>
