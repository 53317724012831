<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#142552] w-[400px] h-[95px] rounded-[10px] border-[1px] border-[#303061]">
        <div class="w-full text-center mt-[10px]">
            <div class="flex text-center items-center py-[4px] bg-[#91CBE5] w-[95%] rounded-[3px] mx-auto">
                <div class="block-title text-[11px] font-bold text-[#142552] text-center mx-auto w-[60%]">{{ $t('trainersBlock.trainerCard.parametersData.title') }}</div>
            </div>
        </div>

        <div class="mx-[15px] text-[#91CBE5] flex items-center justify-between mt-[5px]">
            <div class="flex justify-between w-[20%] items-center align-middle ">
                <div class="w-[70%]">
                    <input :value="height" @change="$emit('update:height', $event.target.value)" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" :placeholder="$t('trainersBlock.trainerCard.parametersData.height_placeholder')">
                </div>
                <div class="text-[#91CBE5] font-normal opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.parametersData.height') }}
                </div>
            </div>
            <div class="flex justify-between w-[25%] items-center align-middle">
                <div class="w-[50%]">
                    <input :value="weight" @change="$emit('update:weight', $event.target.value)" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" :placeholder="$t('trainersBlock.trainerCard.parametersData.weight_placeholder')">
                </div>
                <div class="text-[#91CBE5] font-normal opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.parametersData.weight') }}
                </div>
            </div>
            <div class="flex justify-between w-[30%] items-center align-middle mr-[4px]">
                <div class="w-[30%]">
                    <input :value="breastVolume" @change="$emit('update:breastVolume', $event.target.value)" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" :placeholder="$t('trainersBlock.trainerCard.parametersData.chest_girth_placeholder')">
                </div>
                <div class="text-[#91CBE5] text-end font-normal opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.parametersData.chest_girth') }}
                </div>
            </div>
        </div>
        <div class="mx-[15px] text-[#91CBE5] flex items-center justify-between mt-[1px]">
            <div class="flex justify-between w-[20%] items-center align-middle">
                <div class="w-[70%]">
                    <input :value="leadingFoot" @change="$emit('update:leadingFoot', $event.target.value)" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" :placeholder="$t('trainersBlock.trainerCard.parametersData.main_leg_placeholder')">
                </div>
                <div class="text-[#91CBE5] font-normal opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.parametersData.main_leg') }}
                </div>
            </div>
            <div class="flex justify-between w-[25%] items-center align-middle">
                <div class="w-[50%]">
                    <input :value="footSize" @change="$emit('update:footSize', $event.target.value)" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent w-[90%] border-none truncate placeholder:opacity-60" type="text" :placeholder="$t('trainersBlock.trainerCard.parametersData.leg_size_placeholder')">
                </div>
                <div class="text-[#91CBE5] text-end font-normal opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.parametersData.leg_size') }}
                </div>
            </div>
            <div class="flex justify-between w-[30%] items-center align-middle mr-[5px]">
                <div class="w-[80%]">
                    <input :value="formattedClubStartDate" @change="formattedClubStartDate = $event.target.value" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent border-none w-full truncate placeholder:opacity-60" type="date">
                </div>
                <div class="text-[#91CBE5] text-end font-normal opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.parametersData.club_start') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed } from 'vue'
    import DataInput from '../ui/DataInput.vue'

    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }


    export default {
        name: 'parameters-card',

        props: {
            login: {
                type: String,
                default: ''
            },
            password: {
                type: String,
                default: ''
            },
            playerNumber: {
                type: Number,
                default: 0
            },
            amplua: {
                type: String,
                default: ''
            },
            height: {
                type: Number,
                default: 0
            },
            weight: {
                type: Number,
                default: 0
            },
            breastVolume: {
                type: Number,
                default: 0
            },
            leadingFoot: {
                type: String,
                default: ''
            },
            footSize: {
                type: Number,
                default: 0
            },
            startTraining: {
                type: String,
                default: ''
            },
            clubStartDate: {
                type: String,
                default: ''
            },
        },

        setup(props, { emit }) {
            const formattedClubStartDate = computed({
                get() {
                    if (!props.clubStartDate?.includes('T'))
                        return props.clubStartDate
                    return formateDate(props.clubStartDate)
                },
                set(value) {
                    emit('update:clubStartDate', value)
                }
            })
            
            return {
                formattedClubStartDate
            }
        },

        components: {
            DataInput
        }
    }
</script>

<style lang="scss" scoped>
    .block-title { 
        font-family: 'Avenir Cyrillic Bold';
    }
    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23ABD3EC" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>