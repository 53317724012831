<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="w-[400px] h-[181px] bg-[#142552] rounded-[10px] pt-[10px] border-[1px] border-[#303061]">
        <div class="bg-[#ABD3EC] text-[13px] font-bold text-center rounded-[4px] mx-[14px] py-[2px]">
            {{ $t('studentBlock.studentCard.parametersData.title') }}
        </div>
        <div class="flex justify-between mx-[14px] mt-[7px]">
            <div class="flex justify-between items-center align-middle w-[40%]">
                <div class="flex">
                    <span class="text-[#ABD3EC] mr-[5px] font-semibold text-[13px]">380</span><input :value="login.replace('380', '')" @change="$emit('update:login', '380' + $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="000000000" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.parametersData.login') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[40%]">
                <div>
                    <input :value="password" @change="$emit('update:password', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.parametersData.password_placeholder')" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.parametersData.password') }}
                </div>
            </div>
        </div>
        <hr class="mx-[14px] mt-[7px] bg-[#ABD3EC] fill-[#ABD3EC] stroke-[#ABD3EC] text-[#ABD3EC] opacity-30">
        <div class="flex justify-between mx-[14px] mt-[7px]">
            <div class="flex justify-between items-center align-middle w-[40%]">
                <div class="w-[30%]">
                    <input :value="playerNumber" @change="$emit('update:playerNumber', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[80%] truncate placeholder:opacity-60" placeholder="11" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] w-[70%] opacity-90 text-start text-[11px]">
                    {{ $t('studentBlock.studentCard.parametersData.player_number') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[50%]">
                <div class="w-[55%]">
                    <input :value="dateStartTraining" @change="dateStartTraining = $event.target.value" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.parametersData.club_start') }}
                </div>
            </div>
        </div>

        <div class="flex justify-between mx-[14px] mt-[7px]">
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input :value="height" @change="$emit('update:height', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="160" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px] w-[60%] text-start">
                    {{ $t('studentBlock.studentCard.parametersData.height') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input :value="weight" @change="$emit('update:weight', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="100" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 w-[60%] text-[11px] text-start">
                    {{ $t('studentBlock.studentCard.parametersData.weight') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input :value="breastVolume" @change="$emit('update:breastVolume', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="65" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px] w-[70%] text-end">
                    {{ $t('studentBlock.studentCard.parametersData.chest_girth') }}
                </div>
            </div>
        </div>

        <div class="flex justify-between mx-[14px] mt-[7px]">
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input :value="leadingFoot" @change="$emit('update:leadingFoot', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.parametersData.main_leg_placeholder')" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px] w-[60%] text-start">
                    {{ $t('studentBlock.studentCard.parametersData.main_leg') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input :value="footSize" @change="$emit('update:footSize', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="41" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 w-[60%] text-[11px] text-start">
                    {{ $t('studentBlock.studentCard.parametersData.leg_size') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[70%]">
                    <!-- <input :value="amplua" @change="$emit('update:amplua', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="НП" type="text"> -->
                    <select :value="amplua" @change="$emit('update:amplua', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60">
                        <option class="bg-[#142552]" value="">Не задано</option>
                        <option class="bg-[#142552]" value="ВР">ВР</option>
                        <option class="bg-[#142552]" value="ЦЗ">ЦЗ</option>
                        <option class="bg-[#142552]" value="ЛЗ">ЛЗ</option>
                        <option class="bg-[#142552]" value="ПЗ">ПЗ</option>
                        <option class="bg-[#142552]" value="ЦП">ЦП</option>
                        <option class="bg-[#142552]" value="ПП">ПП</option>
                        <option class="bg-[#142552]" value="ЛП">ЛП</option>
                        <option class="bg-[#142552]" value="ЦФ">ЦФ</option>
                    </select>
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px] w-[70%] text-end">
                    {{ $t('studentBlock.studentCard.parametersData.amplua') }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { ref, computed } from 'vue'
    import DataInput from '../ui/DataInput.vue'

    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'parameters-card',

        props: {
            login: {
                type: String,
                default: ''
            },
            password: {
                type: String,
                default: ''
            },
            playerNumber: {
                type: Number,
                default: 0
            },
            amplua: {
                type: String,
                default: ''
            },
            height: {
                type: Number,
                default: 0
            },
            weight: {
                type: Number,
                default: 0
            },
            breastVolume: {
                type: Number,
                default: 0
            },
            leadingFoot: {
                type: String,
                default: ''
            },
            footSize: {
                type: Number,
                default: 0
            },
            startTraining: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const dateStartTraining = computed({
                get() {
                    if (!props.startTraining.includes('T'))
                        return props.startTraining
                    return formateDate(props.startTraining)
                },
                set(value) {
                    emit('update:startTraining', value)
                }
            })

            return {
                dateStartTraining
            }
        },

        components: {
            DataInput
        }
    }
</script>



<style scoped>

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23ABD3EC" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>