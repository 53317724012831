<template>
    <div @click="showExitConfirmForm = true" style="background-color: rgba(17, 32, 77, .35)"  class="backdrop-blur-[7px] fixed w-full h-[130%] top-[-70px]"></div>
    <exit-confirm-form
        v-if="showExitConfirmForm"
        :alertText="$t('trainersBlock.saveChanges') + ' ' + new_trainer.name + ' ' + new_trainer.surname + '?'"
        class="absolute top-[50%] ml-[110px] w-[260px] left-[50%] translate-x-[-50%] translate-y-[-50%]"

        @doSave="sendNewTrainerData"
        @dontSave="$emit('close'); showExitConfirmForm = false"
        @returnToCurrent="showExitConfirmForm = false"
    />
    <div v-if="!showExitConfirmForm" class="student-card absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ml-[110px] flex">
        <image-cropper 
            v-if="showImageCropper"
            :image="imageToCrop"
            @processedImage="processImage"
        />
        <info-card 

            @loadPhoto="loadImage"
            :trainerPhoto = "trainerPhoto"

            v-model:name="new_trainer.name"
            v-model:surname="new_trainer.surname"
            v-model:patronymic="new_trainer.patronymic"
            v-model:birthDate="new_trainer.birthday"
            v-model:phoneNumber="new_trainer.phone"
            v-model:email="new_trainer.email"
            v-model:nationality="new_trainer.nationality"

        />
        <div class="additional-info mr-[24px] flex flex-col items-center rounded-[10px]">
            <!-- <div class="flex justify-end w-full mt-[-25px] mb-[5px]">
                <img @click="sendNewTrainerData" src="@/assets/icons/cloud_icon.svg" class="mx-2 cursor-pointer">
                <img @click="$emit('close')" src="@/assets/icons/cross_icon.svg" class="cursor-pointer">
            </div> -->
            <auth-data-card 
                class="mb-[20px]"
                v-model:login="new_trainer.login"
                v-model:password="new_trainer.password"
            />
            <parameters-card 
                v-model:height="new_trainer.height"
                v-model:weight="new_trainer.weight"
                v-model:footSize="new_trainer.foot_size"
                v-model:leadingFoot="new_trainer.leading_foot"
                v-model:breastVolume="new_trainer.breast_volume"
                v-model:clubStartDate="new_trainer.club_start_date"
            />
            <documentary-card 
                v-model:nameEng="new_trainer.name_eng"
                v-model:surnameEng="new_trainer.surname_eng"

                v-model:passport="new_trainer.passport"
                :passportPhotoArray="new_trainer.passport_photos"
                @addPassportPhoto="new_trainer.passport_photos.push($event)"
                
                v-model:idNumber="new_trainer.id_number"
                :idNumberPhotoArray="new_trainer.id_photos"
                @addIdNumberPhoto="new_trainer.id_photos.push($event)"

                v-model:passportEndDate="new_trainer.passport_end_date"

                v-model:urkainianPassport="new_trainer.ukrainian_passport"
                :urkainianPassportPhotoArray="new_trainer.ukrainian_passport_files"
                @addUkrPassportPhoto="new_trainer.ukrainian_passport_files.push($event)"
                
                v-model:issuingAuthority="new_trainer.issuing_authority"
                v-model:issuingDate="new_trainer.issuing_date"

                @deletePhoto="ctx => deletePhoto(ctx.currentImage, ctx.currentPhotoType)"
            />
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { addNewTrainer } from '@/services/apiRequests'

    import InfoCard from './card-components/InfoCard.vue'
    import ParametersCard from './card-components/ParametersCard.vue'
    import DocumentaryCard from './card-components/DocumentaryCard.vue'
    import AuthDataCard from './card-components/AuthDataCard.vue'
    import ExitConfirmForm from './ui/ExitConfirmForm.vue'

    import ImageCropper from './ui/ImageCropper.vue'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'trainer-form',

        emits: ['close'],

        setup(props, {emit}) {
            const new_trainer = ref({
                name: '',
                surname: '',
                patronymic: '',
                birthday: '',
                phone: '',
                email: '',
                nationality: 'UKR',

                login: '',
                password: '',

                height: 0,
                weight: 0,
                foot_size: 0,
                leading_foot: '',
                breast_volume: 0,
                club_start_date: '',

                name_eng: '',
                surname_eng: '',
                passport: '',
                passport_photos: [],
                id_number: '',
                id_photos: [],
                passport_end_date: '',
                ukrainian_passport: '',
                ukrainian_passport_files: [],   
                issuing_authority: '',
                issuing_date: ''
            })
            const showExitConfirmForm = ref(false)
            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            })
            const trainerPhoto = ref(null)

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const processImage = (image) => {
                trainerPhoto.value = image
                showImageCropper.value = false
            }

            const deletePhoto = (currentPhoto, currentPhotoType) => {
                console.log(currentPhoto, currentPhotoType)
                if (currentPhotoType === 'passport') {
                    new_trainer.value.passport_photos = new_trainer.value.passport_photos.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'idNumber') {
                    new_trainer.value.id_photos = new_trainer.value.id_photos.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'ukrPassport') {
                    new_trainer.value.ukrainian_passport_files = new_trainer.value.ukrainian_passport_files.filter((photo) => photo !== currentPhoto)
                }
            }

            const sendNewTrainerData = () => {
                console.log(trainerPhoto.value)
                addNewTrainer(new_trainer.value, trainerPhoto.value)
                    .then((response) => {
                        emit('close')
                        emit('updateTrainersList')
                        showExitConfirmForm.value = false
                        new_trainer.value = {
                            name: '',
                            surname: '',
                            patronymic: '',
                            birthday: '',
                            phone: '',
                            email: '',
                            nationality: 'UKR',

                            login: '',
                            password: '',

                            height: 0,
                            weight: 0,
                            foot_size: 0,
                            leading_foot: '',
                            breast_volume: 0,
                            club_start_date: '',

                            name_eng: '',
                            surname_eng: '',
                            passport: '',
                            passport_photos: [],
                            id_number: '',
                            id_photos: [],
                            passport_end_date: '',
                            ukrainian_passport: '',
                            ukrainian_passport_files: [],   
                            issuing_authority: '',
                            issuing_date: ''
                        }
                        trainerPhoto.value = null
                    })
            }

            return {
                new_trainer,
                showImageCropper,
                imageToCrop,
                trainerPhoto,
                loadImage,
                processImage,
                deletePhoto,
                sendNewTrainerData,
                showExitConfirmForm
            }
        },

        components: {
            InfoCard,
            ParametersCard,
            DocumentaryCard,
            AuthDataCard,
            ImageCropper,
            ExitConfirmForm
        }
    }
</script>
