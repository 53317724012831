<template>
    <div>
        <div class="flex justify-between relative left-[10px]">
            <div class="font-semibold text-[#91CBE5]">
                {{ $t('dashboard.incomesByGroups') }}
            </div>
            <div>
                <select :key="yearKey" v-model="currentYear" name="month" class="outline-none relative right-[17px] px-[5px] py-[0.6px] rounded-[4px] w-[64px] border-[#A4A6B3] text-[#91CBE5] text-[13px] border-[1px] bg-transparent">
                    <option v-for="year in generateYears()" :value="year.value" class="bg-[#28375F]">{{ year.label }}</option>
                </select>
                <select :key="monthesKey" v-model="currentMonth" name="month" class="outline-none relative ml-[20px] right-[17px] px-[5px] py-[0.6px] rounded-[4px] w-[144px] border-[#A4A6B3] text-[#91CBE5] text-[13px] border-[1px] bg-transparent">
                    <option v-for="month in generateMonthesAndYear()" :value="month.value" class="bg-[#28375F]">{{ month.label }}</option>
                </select>
            </div>
        </div>

        <div class="flex w-[100%] relative">
            <div>
                <BarOverlineChart 
                    v-if="dataLoadedFlag"
                    class="w-max relative left-[12px]"
                    :data="chartData"
                    :labels="chartLabels"
                    :bottomBarColor="'#41BF74'"
                    :topBarColor="'#ABD3EC'"
                    :lineColor="'#DE3A73'"
                    :height="height"
                    :width="width"
                />
                <div v-else class="placeholder-params relative">
                    <div class="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                        <div class="text-[14px] text-gray-400">Немає даних</div>
                    </div>
                </div>
            </div>
            <div class="h-[230px] absolute right-0 top-[15px] w-[200px] overflow-y-auto scrolltab">
                <div v-if="writeoffsDataLoaded" v-for="writeoffGroup in writeoffsData" class="flex my-[5px]">
                    <div class="text-[#ABD3EC] w-[30%]">{{ writeoffGroup.group_name }}</div>
                    <div class="w-[70%]">
                        <one-line 
                            :percent="writeoffGroup.percent"
                            :value="writeoffGroup.writeoffs"
                            :fullWidth="100"
                        />
                    </div>
                </div>
                <div v-else class="relative h-full w-full">
                    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#de3a73] border-t-transparent absolute top-[50%] left-[50%]"></div>
                </div>
            </div>
        </div>

        <div class="flex justify-between w-max mx-auto mt-[10px]">
            <div class="flex items-center mx-3">
                <div class="w-5 h-2 bg-[#41BF74] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">{{ $t('dashboard.balanceCards.cashIncomes') }}</div>
            </div>
            <div class="flex items-center mx-3">
                <div class="w-5 h-2 bg-[#ABD3EC] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">{{ $t('dashboard.balanceCards.cashlessIncomes') }}</div>
            </div>
            <div class="flex items-center mx-3">
                <div class="w-5 h-2 bg-[#DE3A73] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">{{ $t('dashboard.allIncomes') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { getIncomesByGroups, getWriteoffsByGroups } from '@/services/apiRequests'
    import BarOverlineChart from '../BarOverlineChart.vue'
    import OneLine from '../OneLine.vue'
    import i18n from '@/i18n'
    import { useI18n } from 'vue-i18n'

    export default {
        name: 'IncomesByGroups',

        setup() {
            const chartData = ref([])
            const chartLabels = ref([])
            const dataLoadedFlag = ref(false)

            const screenWidth = ref(window.innerWidth)
            const height = ref(230)
            const width = ref(864)

            const yearKey = ref(uuid.v4())
            const currentYear = ref(new Date().getFullYear())
            const generateYears = () => {
                const years = []
                const currentYearNow = new Date().getFullYear()
                for (let i = 0; i < 5; i++) {
                    years.push({
                        value: currentYearNow - i,
                        label: currentYearNow - i
                    })
                }
                return years
            }

            const currentMonth = ref(0)
            const getMonthByNumber = (number) => {
                switch (number) {
                    case 1:
                        return i18n.global.t('fullMonthes.jan')
                    case 2:
                        return i18n.global.t('fullMonthes.feb')
                    case 3:
                        return i18n.global.t('fullMonthes.mar')
                    case 4:
                        return i18n.global.t('fullMonthes.apr')
                    case 5:
                        return i18n.global.t('fullMonthes.may')
                    case 6:
                        return i18n.global.t('fullMonthes.jun')
                    case 7:
                        return i18n.global.t('fullMonthes.jul')
                    case 8:
                        return i18n.global.t('fullMonthes.aug')
                    case 9:
                        return i18n.global.t('fullMonthes.sep')
                    case 10:
                        return i18n.global.t('fullMonthes.oct')
                    case 11:
                        return i18n.global.t('fullMonthes.nov')
                    case 12:
                        return i18n.global.t('fullMonthes.dec')
                }
            }
            const { t, locale } = useI18n({ useScope: 'global' })
            const monthesKey = ref(uuid.v4())

            watch(locale, () => {
                monthesKey.value = uuid.v4()
            })

            const generateMonthesAndYear = () => {
                const monthes = []
                const year = new Date().getFullYear()
                for (let i = 0; i < 12; i++) {
                    monthes.push({
                        value: `${i + 1} ${currentYear.value}`,
                        label: `${getMonthByNumber(i + 1)} ${currentYear.value}`
                    })
                }
                return monthes
            }

            const getCurrentMonth = () => {
                const date = new Date()
                const month = date.getMonth()
                const year = date.getFullYear()

                return `${month + 1} ${year}`
            }

            const writeoffsDataLoaded = ref(false)
            const writeoffsData = ref([])

            onMounted(async () => {
                currentMonth.value = getCurrentMonth()
                const year = new Date().getFullYear()
                const month = new Date().getMonth() + 1
                const res = (await getIncomesByGroups(year, month))[0]
                console.log('res -----!---- ', res)

                const sumAllIncomes = res.groups.reduce((acc, group) => {
                    return acc + group.cash_incomes + group.cashless_incomes
                }, 0)
                
                if (sumAllIncomes === 0) {
                    return
                }

                res.groups.forEach((group) => {
                    chartData.value.push([group.cash_incomes, group.cashless_incomes])
                    chartLabels.value.push(group.group_name)
                })
                console.log('chartLabels', chartLabels.value)
                dataLoadedFlag.value = true

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                if (screenWidth.value < 1410) {
                    width.value = 900
                } else {
                    width.value = 1070
                }
            })

            watch(currentMonth, async (newValue) => {
                writeoffsDataLoaded.value = false
                dataLoadedFlag.value = false
                chartData.value = []
                console.log('newValue', newValue)
                const [month, year] = newValue.split(' ')
                const res = (await getIncomesByGroups(year, month))[0]

                const sumAllIncomes = res.groups.reduce((acc, group) => {
                    return acc + group.cash_incomes + group.cashless_incomes
                }, 0)
                
                if (sumAllIncomes === 0) {
                    return
                }

                console.log('res == ', res)

                res.groups.forEach((group) => {
                    chartData.value.push([group.cash_incomes, group.cashless_incomes])
                    chartLabels.value.push(group.group_name)
                })

                dataLoadedFlag.value = true

                const writeoffsRes = (await getWriteoffsByGroups(year, month))[0]
                writeoffsData.value = []
                console.log('writeoffsRes', writeoffsRes)
                if (writeoffsRes) {
                    const maxWriteoff = Math.max(...writeoffsRes.groups.map((group) => group.writeoffs))
                    writeoffsData.value = writeoffsRes.groups.map((group) => {
                        return {
                            ...group,
                            percent: (group.writeoffs / maxWriteoff) * 100
                        }
                    })
                }
                writeoffsDataLoaded.value = true
            })

            watch(screenWidth, (newValue) => {
                if (newValue < 1410) {
                    width.value = 900
                } else {
                    width.value = 1070
                }
            })

            return {
                chartData,
                dataLoadedFlag,
                currentMonth,
                generateMonthesAndYear,
                getCurrentMonth,
                chartLabels,
                screenWidth,
                height,
                width,
                writeoffsDataLoaded,
                writeoffsData,
                monthesKey,
                generateYears,
                yearKey,
                currentYear
            }
        },

        components: {
            BarOverlineChart,
            OneLine
        }
    }
</script>

<style lang="scss" scoped>

    .placeholder-params {
        @apply h-[230px] w-[1070px];
    }

    @media (min-width: 1410px) and (max-width: 8000px) {
        .placeholder-params {
            @apply h-[230px] w-[1070px];
        }
    }

    @media (min-width: 0px) and (max-width: 1410px) {
        .placeholder-params {
            @apply h-[230px] w-[900px];
        }
    }

    .scrolltab::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #ABD3EC;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>