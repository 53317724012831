<template>
    <tr class="border-b-[1px] border-[#303061]">
        <td class="px-3 w-[15%] truncate py-2 text-start text-sm">
            <div class="flex flex-col align-middle">
                <div class="truncate text-[#91CBE5]">{{ group.name }}</div>
                <div class="truncate text-[10px] text-gray-400">{{ group.year_range }}</div>
            </div>
        </td>

        <td class="px-3 w-[20%] pt-[15px] text-start flex items-center text-sm">
            <div>
                <!-- <img v-if="photoTrainer == null" width="28" src="@/assets/icons/human_icon.svg"> -->
                <Skeletor v-if="photoTrainer == null && trainer" class="rounded-full z-0" width="28" height="28" pill as="div" :shimmer="true"/>
                <div v-else class="w-[28px] h-[28px] flex items-end rounded-full overflow-hidden border-[#91CBE5] border-[1px]">
                    <img v-if="trainer?.photo" :src="photoTrainer">
                    <img v-else src="@/assets/student-photo-placeholder-tpbg.png" class="relative bottom-[-5px]">
                </div>
            </div>
            <div class="ml-[5px] text-[#91CBE5]">
                <span v-if="trainerName != 'Не задано'" class="truncate">{{ trainerName }}</span>
                <span v-else-if="trainer == null" class="truncate"> {{ $t('groupsPage.groupCard.trainerNotSelected') }} </span>
                
                <Skeletor v-else class="rounded-[10px]" width="70" height="15" pill as="div" :shimmer="true"/>
            </div>    
        </td>
        <td class="w-[15%] text-[#91CBE5] text-end relative">
            <div v-if="studentsCount != undefined" class="absolute left-[0] top-[50%] translate-y-[-50%] text-end w-[60px]">{{ studentsCount }}</div>
            <Skeletor v-else class="rounded-[10px]" width="40" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 py-2 text-end text-sm w-[50%]">
            <button @click="$emit('openGroupCard')" class="px-2 py-1 rounded-[10px] text-sm font-medium">
                <img src="@/assets/icons/pen_icon.svg" class="w-6 h-6">
            </button>
            <button @click="deleteGroup" class="px-2 py-1 rounded-[10px] text-sm font-medium text-red-600">
                <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6 ">
            </button>
        </td>
    </tr>
</template>

<script>
    import { getTrainerById, getStudentsCountByGroupId, deleteGroupRequest } from '@/services/apiRequests'
    import { ref, onBeforeMount, watch, computed } from 'vue'
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'group-card',

        props: {
            group: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const trainer = ref(null)

            watch(() => props.group.trainer_id, () => {
                console.log('updated id')
                getTrainerById(props.group.trainer_id).then(res => {
                    trainer.value = res.data
                })
            })

            const photoTrainer = computed(
                () => trainer.value === null ? null : process.env.VUE_APP_API_URL + 'static/' + trainer.value.photo
            )
            const trainerName = computed(
                () => trainer.value === null ? 'Не задано' : trainer.value.surname + ' ' + trainer.value.name[0] + '.'
            )

            const studentsCount = ref(undefined)

            const deleteGroup = () => {
                emit('deleteGroup', props.group)
            }

            onBeforeMount(async () => {
                studentsCount.value = 0

                // setInterval(async () => {
                //     const countRes = await getStudentsCountByGroupId(props.group.group_id)
                //     studentsCount.value = countRes.data > 0 ? countRes.data : 0
                // }, 1000)

                const countRes = await getStudentsCountByGroupId(props.group.group_id)
                studentsCount.value = countRes.data > 0 ? countRes.data : 0
                console.log('countRes.data', countRes.data)

                if (props.group.trainer_id === null) return

                const res = await getTrainerById(props.group.trainer_id)
                trainer.value = res.data 
            })

            return {
                photoTrainer, 
                trainerName,
                studentsCount,
                trainer,
                deleteGroup,
            }
        },

        components: {
            Skeletor
        }
    }
</script>

<style lang="scss" scoped>

    .vue-skeletor {
        background-color: #ccc;
        animation: shimmer 1.5s infinite;
        animation-duration: 2s; 
        animation-timing-function: ease-in-out;
    }

    /* Default keyframes used in skeletor */
    @keyframes shimmer {
        0% {
            opacity: .5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: .5;
        }
    }

</style>