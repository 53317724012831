<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between mt-2">
            <div class="search-bar flex items-center border-[#91CBE5] border-[1px] bg-[#91CBE5] bg-opacity-30 px-[10px] rounded-[6px]">
                <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input class="text-[#91CBE5] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#91CBE5] outline-none w-[250px]" :placeholder="$t('menuBar.searchPlaceholder') + '...'" type="text">
                <!-- <button class="bg-slate-500 hover:bg-slate-600 text-slate-200 px-2 py-1 rounded-md ml-2">Фільтр</button> -->
            </div>
            <div class="filter">
                <button @click="$emit('addLocation')" class="bg-[#de3a73] hover:bg-[#bf2c5f] w-[228px] text-white px-4 py-[6px] text-[14px] rounded-md">{{ $t('menuBar.addButton') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'menu-bar'
    }
</script>
