<template>
    <div v-if="componentName != 'Login' && componentName != 'Loading'">
        <div v-if="windowWidth > 1200" class="flex">
            <system-sidebar class="w-[258px]" />
            <div class="ml-[258px] w-[100%]">
                <!-- <div 
                    class="flex items-center mt-[10px] mb-[20px] mx-auto relative w-[93%] justify-between"
                    :class="{
                        'right-[40px]' : componentName == 'Dashboard',
                        'right-[1px]' : componentName != 'Dashboard',
                    }"
                >
                    <div class="text-white text-[20px] font-semibold">
                        {{ pageName }}
                    </div>
                    <div class="flex items-center">
                        <div>
                            <div class="text-[#91CBE5]">{{ adminObject.admin_name }} {{ adminObject.admin_surname }}</div>
                        </div>
                        <div class="w-10 h-10 ml-[10px] border-[#91CBE5] border-[1px] rounded-full overflow-hidden">
                            <div class="h-full w-full flex justify-center items-center text-[#91CBE5] bg-[#91CBE5] bg-opacity-20">{{ adminObject.admin_name[0] + adminObject.admin_surname[0] }}</div>
                        </div>
                    </div>
                </div> -->
                <div 
                    class="fixed z-[70] pr-[30px] pt-[10px] top-0 w-full right-0 flex justify-between bg-[#11204D]"
                >
                    <div class="w-[300px]">

                    </div>
                    <div
                        class="flex relative ml-[30px] pt-[10px] top-[-10px] items-center justify-between bg-[#11204D] w-full "
                    >
                        <div class="text-white text-[20px] mt-[5px] font-semibold">
                            {{ pageName }}
                        </div>
                        <div 
                            class="flex items-center"
                            :class="{
                                'mr-[10px]' : windowWidth < 1410,
                            }"    
                        >
                            <div>
                                <div class="text-[#91CBE5]">{{ adminObject.admin_name }} {{ adminObject.admin_surname }}</div>
                            </div>
                            <div class="w-10 h-10 ml-[10px] border-[#91CBE5] border-[1px] rounded-full overflow-hidden">
                                <div class="h-full w-full flex justify-center items-center text-[#91CBE5] bg-[#91CBE5] bg-opacity-20">{{ adminObject.admin_name[0].toUpperCase() + adminObject.admin_surname[0].toUpperCase()    }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <component class="bg-[#11204D] mt-[75px]" :is="componentName"></component>
            </div>
        </div>
        <div v-else>
            <Transition>
                <system-sidebar v-touch:swipe.left="swipeHandlerLeft" class="w-[175px] absolute" v-if="isSidebarVisible" />
            </Transition>
            <div v-touch:swipe.right="swipeHandler" class="absolute w-[100px] h-full" v-if="!isSidebarVisible"></div>
            <div 
                    class="bg-[#11204D] fixed py-[15px] top-0 w-[90%] left-[50%] translate-x-[-50%] flex justify-between"
                >
                <div class="text-white text-[20px] font-semibold">
                    {{ pageName }}
                </div>
                <div class="flex items-center">
                    <div>
                        <div class="text-[#91CBE5]">{{ adminObject.admin_name }} {{ adminObject.admin_surname }}</div>
                    </div>
                    <div class="w-10 h-10 ml-[10px] border-[#91CBE5] border-[1px] rounded-full overflow-hidden">
                        <div class="h-full w-full flex justify-center items-center text-[#91CBE5] bg-[#91CBE5] bg-opacity-20">{{ adminObject.admin_name[0] + adminObject.admin_surname[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-[90px]">
                <component class="bg-[#11204D] " :is="componentName"></component>
            </div>
        </div>
    </div>
    <div v-else>
        <component class="bg-[#11204D]" :is="componentName"></component>
    </div>
</template>

<script>
    import {ref, onMounted, onUpdated} from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import Dashboard from '@/views/Dashboard/Dashboard.vue'
    import Login from '@/views/Login/Login.vue'
    import Trainers from '@/views/Trainers/Trainers.vue'
    import Groups from '@/views/Groups/Groups.vue'
    import Students from '@/views/Students/Students.vue'
    import TransactionLog from '@/views/TransactionLog/TransactionLog.vue'
    import CurrentStudent from '@/views/CurrentStudent/CurrentStudent.vue'
    import Locations from '@/views/Locations/Locations.vue'
    import Calendar from '@/views/Calendar/Calendar.vue'
    import Incomes from '@/views/Finance/Incomes/Incomes.vue'
    import Outlays from '@/views/Finance/Outlays/Outlays.vue'
    import Transactions from '@/views/Finance/Transactions/Transactions.vue'
    import Admins from '@/views/Admins/Admins.vue'
    import OutlaysCategories from '@/views/Finance/OutlaysCategories/OutlaysCategories.vue'
    import ClubOutlays from '@/views/Finance/ClubOutlays/ClubOutlays.vue'
    import Settings from '@/views/Settings/Settings.vue'

    import Loading from '@/views/Loading.vue'

    import SystemSidebar from '@/components/SystemSidebar.vue'

    import { getAdmin } from '@/services/apiRequests'

    export default {
        setup() {
        
            // Basic const
            const route = useRoute()
            const router = useRouter()
            const windowWidth = ref(0)
            const isSidebarVisible = ref(false)
            const isLoginSuccess = ref(false)
            const adminObject = ref({})
            const pageName = ref('')

            const setPageName = (component) => {
                switch (component) {
                    case 'Dashboard':
                        pageName.value = 'Показники'
                        break;
                    case 'Groups':
                        pageName.value = 'Групи'
                        break;
                    case 'Trainers':
                        pageName.value = 'Тренери'
                        break;
                    case 'Students':
                        pageName.value = 'Учні'
                        break;
                    case 'Locations':
                        pageName.value = 'Локації'
                        break;
                    case 'Calendar':
                        pageName.value = 'Календар'
                        break;
                    case 'Incomes':
                        pageName.value = 'Надходження'
                        break;    
                    case 'Outlays':
                        pageName.value = 'Нарахування'
                        break;   
                    case 'Transactions':
                        pageName.value = 'Транзакції'
                        break;    
                    case 'Admins':
                        pageName.value = 'Адміни'
                        break;    
                    case 'OutlaysCategories':
                        pageName.value = 'Категорії витрат'
                        break;
                    case 'ClubOutlays':
                        pageName.value = 'Витрати клубу'
                        break;
                    case 'Settings':
                        pageName.value = 'Налаштування'
                        break;
                }
            }

            //Login routing            
            const componentName = ref('Loading')
            onMounted(async () => { 
                windowWidth.value = window.innerWidth
                window.addEventListener('resize', () => windowWidth.value = window.innerWidth)

                setPageName(route.meta.component)

                if (sessionStorage.access_token && sessionStorage.refresh_token) {
                    const adminResponse = await getAdmin()
                    adminObject.value = adminResponse.data
                    if (adminResponse.data.status == 'success') {
                        componentName.value = route.meta.component
                        isLoginSuccess.value = true
                    } else {
                        componentName.value = 'Login'
                    }
                } else {
                    componentName.value = 'Login'
                }
            })

            const swipeHandler = () => {
                isSidebarVisible.value = true
            }

            const swipeHandlerLeft = () => {
                isSidebarVisible.value = false
            }

            onUpdated(() => {
                if(isLoginSuccess.value) {
                    componentName.value = route.meta.component
                    setPageName(route.meta.component)
                }
            })

            return {
                componentName, 
                windowWidth,
                isSidebarVisible,
                swipeHandler,
                swipeHandlerLeft,
                adminObject,
                pageName
            }
        }, 

        components: {
            Dashboard,
            Login,
            Trainers,
            Groups,
            Students,
            SystemSidebar,
            TransactionLog,
            CurrentStudent,
            Locations,
            Calendar,
            Loading,
            Incomes,
            Outlays,
            Transactions,
            Admins,
            OutlaysCategories,
            ClubOutlays,
            Settings
        }
    }

</script>

<style lang="scss">

    .v-enter-active,
    .v-leave-active {
        transition: all 0.3s ease-in-out;
    }

    .v-enter-from,
    .v-leave-to {
        transform: translateX(-100px);
        opacity: 0;
    }

</style>