<template>
    <div style="background-color: rgba(17, 32, 77, .35)" class="backdrop-blur-[7px] fixed w-full z-40 h-full top-0"></div>
    <ImageViewer v-if="viewImageFlag" @close="viewImageFlag = false" @delete="deleteImage" :imageUrl="viewImageURL" altText="dddd" class="z-[150]"/>
    <div v-if="loadingInWindow" style="background-color: rgba(17, 32, 77, .35)" class="fixed w-full z-[70] h-full top-0"></div>
    <div v-if="loadingInWindow" class="z-[90] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#de3a73] border-t-transparent absolute top-[50%] left-[50%] ml-[-175px] mt-[-80px]"></div>
    </div>
    <div v-if="loading" class="z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#de3a73] border-t-transparent absolute top-[50%] left-[50%] ml-[-175px] mt-[-80px]"></div>
    </div>
    <div 
        v-else-if="!viewImageFlag" 
        class="bg-[#11204D] border-[1px] border-[#303061] z-50 ml-[100px] rounded-[10px] w-[512px] px-[17px] py-[27px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
        :class="{
            'scale-[90%]' : screenWidth <= 1600,
        }"        
    >
        <div class="flex justify-center">
            <img src="@/assets/logo.svg" class="w-[56px]">
        </div>
        <div class="text-[18px] text-center mt-[10px] text-white font-semibold">
            Створення витрати
        </div>

        <div class="mt-[20px]">
            <div class="text-[#8C8C8C] font-medium text-[14px]">
                введіть назву для витрати
            </div>
            <div class="mt-[3px]">
                <input 
                    v-model="outlayName" 
                    class="w-full border-[1px] rounded-[6px] text-[#fff] bg-opacity-20 py-[5px] pl-[5px] outline-none" 
                    :class="{'border-[#9E9E9E] bg-[#474849]': validation.name || outlayName != '', 'border-[#FF4D4D] bg-[#FF4D4D]': !validation.name && outlayName == ''}"
                    type="text" 
                    name="name"
                >
            </div>
        </div>

        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    оберіть дату
                </div>
                <div class="mt-[3px]">
                    <input 
                        :value="formattedDate" 
                        @change="setDate" 
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                        :class="{'border-[#9E9E9E] bg-[#474849]': validation.date || formattedDate != null, 'border-[#FF4D4D] bg-[#FF4D4D]': !validation.date && formattedDate == null}"
                        type="date" 
                        name="name"
                    >
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    введіть суму витрати
                </div>
                <div class="mt-[3px]">
                    <input 
                        v-model="outlaySum" 
                        class="w-full border-[1px] rounded-[6px] text-[#fff] bg-opacity-20 py-[5px] pl-[5px] outline-none" 
                        :class="{'border-[#9E9E9E] bg-[#474849]': validation.sum || outlaySum != null, 'border-[#FF4D4D] bg-[#FF4D4D]': !validation.sum && outlaySum == null}"
                        type="number" 
                        name="name"
                    >
                </div>
            </div>
        </div>

        <div class="mt-[20px]">
            <div class="text-[#8C8C8C] font-medium text-[14px]">
                введіть опис витрати
            </div>
            <div class="mt-[3px]">
                <textarea 
                    v-model="outlayDescription" 
                    rows="5" 
                    class="w-full border-[1px] rounded-[6px] text-[#fff] bg-opacity-20 py-[5px] pl-[5px] outline-none" 
                    :class="{'border-[#9E9E9E] bg-[#474849]': validation.description || outlayDescription != '', 'border-[#FF4D4D] bg-[#FF4D4D]': !validation.description && outlayDescription == ''}"
                    type="number" 
                    name="name"
                ></textarea>
            </div>
        </div>

        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    категорія витрати
                </div>
                <div class="mt-[3px]">
                    <select v-model="selectedCategory" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option v-for="category in allCategories" class="bg-[#11204D] text-white" :value="`${category.id}|${category.name}`">{{ category.name }}</option>
                    </select>
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    підкатегорія витрати
                </div>
                <div class="mt-[3px]">
                    <select v-model="selectedSubcategory" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option v-for="subcategory in allSubcategories" class="bg-[#11204D] text-white" :value="`${subcategory.id}|${subcategory.name}`">{{ subcategory.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    завантажити чек, рахунок
                </div>
                <div class="mt-[12px] flex justify-between">
                    <div class="flex">
                        <img v-for="image in outlay.additional_files" @click="viewSavedPhoto(image)" src="@/assets/icons/picture_icon.svg" class="mr-[10px] cursor-pointer">
                        <img v-for="image in allImages" @click="viewPhoto(image)" src="@/assets/icons/picture_icon.svg" class="mr-[10px] cursor-pointer">
                    </div>
                    <div>
                        <img @click="uploadPhoto" src="@/assets/icons/upload_icon_light.svg" class="cursor-pointer">
                    </div>
                    <input ref="imagesInput" @change="selectPhoto($event)" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none hidden" type="file" name="additional_files">
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    оберіть джерело витрати
                </div>
                <div class="mt-[3px]">
                    <select 
                        v-model="senderBalanceObject" 
                        class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none"
                        :class="{'border-[#9E9E9E] bg-[#474849]': validation.balance, 'border-[#FF4D4D] bg-[#FF4D4D]': !validation.balance}"    
                    >
                        <option class="bg-[#11204D] text-white" :value="`${bankBalance.balance_id}|${bankBalance.label}`">Банк ({{ bankBalance.state.toFixed(2) }})</option>
                        <option class="bg-[#11204D] text-white" :value="`${cashBalance.balance_id}|${cashBalance.label}`">Готівка ({{ cashBalance.state.toFixed(2) }})</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="w-full mt-[25px]">
            <div class="w-full flex justify-center">
                <button @click="proccessOutlay" class="bg-[#41BF74] text-white w-[260px] mx-auto font-medium h-[28px] rounded-[5px]">
                    Зберегти
                </button>
            </div>
            <div class="w-full mt-[10px] flex justify-center">
                <button @click="$emit('close')" class="w-[260px] bg-[#91CBE5] font-medium h-[28px] mx-auto rounded-[5px] text-[#11204D]">
                    Відмінити
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import { getServiceBalanceObjectByLabel, getBalanceObject, getAdmin, getAllOutlayCategories, getSubcategoriesByCategoryId, updateClubOutlay } from '@/services/apiRequests'
    import ImageViewer from '../ui/ImageViewer.vue'

    async function readFileObjectAsDataURL (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                resolve(event.target.result)
            }
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }

    export default {
        name: 'update-club-outlay',

        props: {
            outlay: {
                type: Object,
                default: () => {}
            }
        },

        setup(props, { emit }) {
            const loading = ref(true)
            const loadingInWindow = ref(false)

            const bankBalance = ref(null)
            const cashBalance = ref(null)

            const cachedSum = ref(props.outlay.amount) 

            const allCategories = ref([])
            const allSubcategories = ref([])

            const selectedCategory = ref(null)
            const selectedSubcategory = ref(null)

            const outlaySum = ref(props.outlay.amount)
            const outlayName = ref(props.outlay.name)
            const outlayDate = ref(new Date(props.outlay.date.split('T')[0]))
            const outlayDescription = ref(props.outlay.label)

            const senderBalanceObject = ref(null)
            const senderBalanceId = ref(props.outlay.sender_balance_id)
            const senderBalanceName = ref(props.outlay.sender_balance_name)
            const senderBalanceState = ref(null)

            const adminObject = ref(null)

            const imagesInput = ref(null)
            const allImages = ref([])
            const viewImageObject = ref({})
            const viewImageURL = ref('')
            const viewImageFlag = ref(false)


            const screenWidth = ref(window.innerWidth)
            watch(window.innerWidth, () => {
                screenWidth.value = window.innerWidth
            })

            const uploadPhoto = () => {
                imagesInput.value.click()
            }

            const selectPhoto = (event) => {
                allImages.value.push(event.target.files[0])
            }

            const viewSavedPhoto = async (image) => {
                viewImageURL.value = process.env.VUE_APP_API_URL + 'static/' + image
                viewImageObject.value = image
                viewImageFlag.value = true
            }

            const deleteImage = () => {
                allImages.value = allImages.value.filter((img) => {
                    return img !== viewImageObject.value
                })
                viewImageFlag.value = false
            }

            const formattedDate = computed(() => {
                if (outlayDate.value) {
                    const day = outlayDate.value.getDate() < 10 ? `0${outlayDate.value.getDate()}` : outlayDate.value.getDate()
                    const month = outlayDate.value.getMonth() + 1 < 10 ? `0${outlayDate.value.getMonth() + 1}` : outlayDate.value.getMonth() + 1
                    const year = outlayDate.value.getFullYear()
                    console.log(`${year}-${month}-${day}`)
                    return `${year}-${month}-${day}`
                }
            })
            const setDate = (date) => {
                console.log('set date --------- ', date.target.value)
                outlayDate.value = new Date(date.target.value)
            }

            const embedFiles = ref([])

            const validation = ref({
                sum: true,
                name: true,
                date: true,
                description: true,
                balance: true
            })

            const validateForm = () => {
                validation.value.sum = outlaySum.value !== null && cachedSum.value + senderBalanceState.value >= outlaySum.value
                validation.value.name = outlayName.value !== ''
                validation.value.date = outlayDate.value !== null
                validation.value.description = outlayDescription.value !== ''
                validation.value.balance = senderBalanceObject.value !== null && cachedSum.value + senderBalanceState.value >= outlaySum.value
            }

            onMounted(async() => {
                console.log('props.outlay', props.outlay)
                const admin = await getAdmin()
                adminObject.value = admin.data
                console.log(adminObject.value)
                const bankBalanceResponse = await getServiceBalanceObjectByLabel('Bank')
                const cashBalanceResponse = await getBalanceObject(admin.data.cash_balance_id)
                
                const allCategoriesRequest = await getAllOutlayCategories()
                allCategories.value = allCategoriesRequest

                const subcategoriesRequest = await getSubcategoriesByCategoryId(allCategoriesRequest[0].id)
                allSubcategories.value = subcategoriesRequest

                selectedCategory.value = `${props.outlay.category_id}|${props.outlay.category_name}`
                selectedSubcategory.value = `${props.outlay.subcategory_id}|${props.outlay.subcategory_name}`

                bankBalance.value = bankBalanceResponse.balance
                cashBalance.value = cashBalanceResponse.balance

                senderBalanceObject.value = `${props.outlay.sender_balance_id}|${props.outlay.sender_balance_name}`
                loading.value = false
            })

            const proccessOutlay = async() => {
                loadingInWindow.value = true

                if (senderBalanceObject.value) {
                    senderBalanceState.value = senderBalanceObject.value.split('|')[0] == cashBalance.value.balance_id ? cashBalance.value.state : bankBalance.value.state  
                }

                validateForm()
                console.log('validation', validation.value)
                if (validation.value.sum && validation.value.name && validation.value.date && validation.value.description && validation.value.balance) {                
                    const senderBalanceObjectArray = senderBalanceObject.value.split('|')
                    const senderBalanceId = senderBalanceObjectArray[0]
                    const senderBalanceName = senderBalanceObjectArray[1]
                    
                    const categoryObjectArray = selectedCategory.value.split('|')
                    const categoryId = categoryObjectArray[0]
                    const categoryName = categoryObjectArray[1]

                    const subcategoryObjectArray = selectedSubcategory.value.split('|')
                    const subcategoryId = subcategoryObjectArray[0]
                    const subcategoryName = subcategoryObjectArray[1]

                    const newOutlay = {
                        "amount": outlaySum.value,
                        "date": outlayDate.value,
                        "label": outlayDescription.value,
                        "name": outlayName.value,
                        "sender_balance_id": senderBalanceId,
                        "sender_balance_name": senderBalanceName,
                        "category_id": categoryId,
                        "category_name": categoryName,
                        "subcategory_id": subcategoryId,
                        "subcategory_name": subcategoryName,
                        "creator_id": adminObject.value.cash_balance_id,
                        "creator_name": adminObject.value.admin_name + ' ' + adminObject.value.admin_surname
                    }
                    await updateClubOutlay(props.outlay.tx_id, newOutlay, allImages.value)

                    console.log('outlayRequest -------- ', adminObject.value)
                    loadingInWindow.value = false
                    emit('updateTransactions')
                    emit('close')
                } else {
                    loadingInWindow.value = false
                }
            }

            const viewPhoto = async (image) => {
                viewImageURL.value = await readFileObjectAsDataURL(image)
                viewImageObject.value = image
                viewImageFlag.value = true
            }

            watch(selectedCategory, async() => {
                loadingInWindow.value = true
                const categoryObjectArray = selectedCategory.value.split('|')
                const categoryId = categoryObjectArray[0]

                const subcategoriesRequest = await getSubcategoriesByCategoryId(categoryId)
                console.log('selectedCategory.value ------- ', subcategoriesRequest)
                allSubcategories.value = subcategoriesRequest
                if (selectedSubcategory.value.length == 0) {
                    if (subcategoriesRequest.length > 0) {
                        selectedSubcategory.value = `${subcategoriesRequest[0].id}|${subcategoriesRequest[0].name}`
                    } else {
                        selectedSubcategory.value = null
                    }
                }
                loadingInWindow.value = false
            })

            return {
                loading,
                bankBalance,
                cashBalance,
                allCategories,
                allSubcategories,
                loadingInWindow,
                selectedCategory,
                selectedSubcategory,
                embedFiles,
                outlaySum,
                outlayName,
                outlayDate,
                outlayDescription,
                formattedDate,
                setDate,
                proccessOutlay,
                senderBalanceObject,
                senderBalanceId,
                senderBalanceName,
                adminObject,
                imagesInput,
                allImages,
                uploadPhoto,
                selectPhoto,
                viewSavedPhoto,
                viewImageObject,
                viewImageURL,
                viewImageFlag,
                deleteImage,
                validation,
                viewPhoto,
                screenWidth
            }
        },

        components: {
            ImageViewer
        }
    }
</script>

<style lang="scss" scoped>

</style>