<template>
    <tr class="border-b-[1px] border-[#303061]">
        <td class="px-3 py-2 text-start text-sm w-[20.86%] text-[#91CBE5]">
            <span v-if="income.receiverName">{{ income.receiverName.includes('Баланс Wayforpay') ? 'Баланс платіжної системи' : income.receiverName }}</span>
            <Skeletor v-else class="rounded-[10px]" width="70" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 py-2 text-start text-sm w-[22.43%] text-[#91CBE5]">
            <span v-if="income.receiverName">{{ income.receiverStatus }}</span>
            <Skeletor v-else class="rounded-[10px]" width="70" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 py-2 text-start text-sm w-[10.88%] text-[#91CBE5]">{{ income.transactionType }}</td>
        <td class="px-3 py-2 text-end text-sm w-[10.00%] text-green-500">{{ income.amount.toFixed(2).toLocaleString() }}</td>
        <td class="px-3 py-2 text-center text-sm w-[5.68%]"></td>
        <td class="px-3 py-2 text-start text-sm w-[13.67%] text-[#91CBE5]">{{ income.date }}</td>
        <td class="px-3 py-2 text-start text-sm w-[8.65%] text-[#91CBE5]">{{ income.time }}</td>
        <td class="px-3 py-2 text-start text-sm  flex justify-end">
            <img src="@/assets/icons/invoice_icon.svg" class="cursor-pointer mr-[10px] " @click="fullView = !fullView">
        </td>
    </tr>
    <tr v-if="fullView">
        <td colspan="3" class="w-full text-white">
            <div class="px-3 my-[20px]">
                <div v-if="balanceName" class="text-sm">
                    {{ $t('paymentsBlock.row.balanceName') }}: <span class="font-semibold">{{ balanceName }}</span>
                </div>
                <div v-if="balanceId" class="text-sm mt-[10px]">
                    {{ $t('paymentsBlock.row.balanceID') }}: <router-link :to="'/incomes/balance_id/' + balanceId" class="text-blue-400 hover:underline">{{ balanceId }}</router-link>
                </div>
                <div class="text-sm mt-[20px]">
                    {{ $t('paymentsBlock.row.transactionComment') }}: <i>«{{ income.label }}»</i>
                </div>
                <div class="text-sm mt-[10px]">
                    {{ $t('paymentsBlock.row.transactionID') }}: <router-link :to="'/incomes/tx_id/' + income.id" class="text-blue-400 hover:underline">{{ income.id }}</router-link>
                </div>
            </div>
        </td>
        <td colspan="5" class="w-full text-white">
            <div 
                class="w-full relative"
                :class="{
                    'mt-[-62px]' : income.additional_files?.length <= 0
                }"
                v-if="embedTxFlag"
            >
                <table class="w-full">
                    <thead>
                        <tr>
                            <th colspan="5" class="font-semibold text-start">{{ $t('paymentsBlock.row.embedTransaction') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-sm">
                            <td class="w-[20%]">{{ embedTxView.receiverName }}</td>
                            <td class="w-[20%]">{{ embedTxView.amount.toFixed(2) }}</td>
                            <td class="w-[20%]">{{ embedTxView.date }}</td>
                            <td class="w-[20%]">{{ embedTxView.time }}</td>
                            <td class="w-[20%]">
                                <button @click="$router.push('/incomes/tx_id/' + embedTxView.id)" class="bg-gray-500 text-[12px] text-white w-[100px] px-1 py-[2px] rounded-md">{{ $t('see') }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div 
                v-if="income.additional_files?.length > 0" 
                class="w-full mt-[15px]"
                :class="{
                    'mt-[-64px]' : !embedTxFlag
                }"    
            >
                <div class="w-full relative">
                    <div class="font-semibold">
                        {{ $t('paymentsBlock.row.additionalFiles') }}
                    </div>
                    <div class="mt-[5px]">
                        <div v-for="file in income.additional_files" @click="$emit('showPicture', file)" class="w-[70px] text-[14px] text-center bg-gray-600 hover:bg-opacity-80 rounded-lg cursor-pointer text-white">Файл 1</div>
                    </div>
                </div>
            </div>
        </td>
    </tr>
    <tr v-if="fullView">
        <td colspan="8">
            <div class="w-full h-px border-b-[0.6px] border-gray-100"></div>
        </td>
    </tr>
</template>

<script>
    import { getBalanceObject, getVirtualIncomeById, getRealParentTransactionByVirtualTransactionId } from '@/services/apiRequests'
    import { ref, onMounted, watch, computed, onUpdated } from 'vue'
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'income-row',

        props: {
            income: {
                type: Object,
                required: true
            }
        },

        emits: ['addRecieverName', 'addRecieverStatus'],

        setup(props, { emit }) {
            const fullView = ref(false)
            const balanceName = ref('')
            const balanceId = ref('')

            const embedVirtualTransaction = ref({})
            const embedIncomeBalane = ref({})
            const embedTxView = ref({
                receiverName: '',
                amount: 0,
                date: '',
                time: '',
                id: ''
            })
            const embedTxFlag = ref(false)

            const updateData = async () => {
                let balanceObject = null
                try {
                    balanceObject = (await getBalanceObject(props.income.receiverBalanceId)).balance
                } catch {
                    balanceObject = null
                }
                let receiverName = ''
                let receiverStatus = ''
                console.log('balanceObject ------ ', balanceObject)
                if (balanceObject) {
                    receiverName = balanceObject.label.split(' ').reverse().slice(0, 2).reverse().map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
                    receiverStatus = balanceObject.label.includes('учня') ? 'Учень' : balanceObject.label.includes('тренера') ? 'Тренер' : 'Сервіс' 
                    const paymentSystem = props.income.label.includes('Wayforpay') ? 'Wayforpay' : props.income.label.includes('IPay') ? 'IPay' : null
                    balanceName.value = balanceObject.label.includes('Wayforpay') || balanceObject.label.includes('IPay') ? 'Баланс платіжної системи ' + paymentSystem : balanceObject.label
                    balanceId.value = balanceObject.balance_id
                } else {
                    receiverName = props.income.label.split(' ').reverse()[0]
                    receiverStatus = 'невизначено'
                    balanceName.value = null
                    balanceId.value = null
                }

                if (props.income.embedVirtualIncomeId) {
                    embedTxFlag.value = true
                    embedVirtualTransaction.value = (await getVirtualIncomeById(props.income.embedVirtualIncomeId)).transaction
                    embedIncomeBalane.value = (await getBalanceObject(embedVirtualTransaction.value.receiver_balance_id)).balance
                    embedTxView.value.receiverName = embedIncomeBalane.value.label.split(' ').reverse().slice(0, 2).reverse().map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
                    embedTxView.value.amount = embedVirtualTransaction.value.amount
                    embedTxView.value.date = embedVirtualTransaction.value.date.split('T')[0].split('-').reverse().join('.')
                    embedTxView.value.time = embedVirtualTransaction.value.date.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')
                    embedTxView.value.id = embedVirtualTransaction.value.tx_id
                }  
                
                if (props.income.transactionType == 'Віртуальна') {
                    try {
                        const realParentTransaction = (await getRealParentTransactionByVirtualTransactionId(props.income.id)).transaction
                        console.log('realParentTransaction ----------- ', realParentTransaction)
                        embedTxView.value.receiverName = realParentTransaction.label.includes('Wayforpay') ? 'Wayforpay' : realParentTransaction.label.includes('Cashbox') ? 'Каса' : realParentTransaction.label.includes('Bank') ? 'Банк' : realParentTransaction.label.includes('Liqpay') ? 'Liqpay' : realParentTransaction.label.includes('IPay') ? 'IPay' : 'Тренер'
                        embedTxView.value.amount = realParentTransaction.amount
                        embedTxView.value.date = realParentTransaction.date.split('T')[0].split('-').reverse().join('.')
                        embedTxView.value.time = realParentTransaction.date.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')
                        embedTxView.value.id = realParentTransaction.tx_id
                        embedTxFlag.value = true
                    } catch {
                        embedTxFlag.value = false
                    }
                }
                
                emit('addRecieverName', receiverName)
                emit('addRecieverStatus', receiverStatus)
            }

            onMounted(async () => {
                console.log('INCOME', props.income.additional_files)
                await updateData()
            })

            onUpdated(async () => {
                await updateData()
            })

            return {
                fullView,
                balanceName,
                balanceId,
                embedVirtualTransaction,
                embedTxView,
                embedTxFlag
            }
        },

        components: {
            Skeletor
        }
    }
</script>

<style lang="scss" scoped>

    .vue-skeletor {
        background-color: #ccc;
        animation: shimmer 1.5s infinite;
        animation-duration: 2s; 
        animation-timing-function: ease-in-out;
    }

    /* Default keyframes used in skeletor */
    @keyframes shimmer {
        0% {
            opacity: .5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: .5;
        }
    }

</style>