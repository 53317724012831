<template>
    <tr class="border-b-[1px] border-[#303061]">
        <td class="px-3 py-2 text-start text-sm w-[10%] text-[#91CBE5]">
            {{ transaction.date.split('T')[0].split('-').reverse().join('.') }}
            <!-- <Skeletor v-else class="rounded-[10px]" width="70" height="15" pill as="div" :shimmer="true"/> -->
        </td>
        <td class="px-3 py-2 truncate text-start text-sm w-[20%] text-[#91CBE5]">
            <div v-if="showSender">{{ senderBalance.label }}</div>
            <div v-else class="px-3 py-2 truncate text-start text-sm w-[100%] bg-gray-500 animate-pulse rounded-full">{{ senderBalance.label }}</div>
        </td>
        
        <td class="px-3 py-2 text-start text-sm w-[15%] text-[#91CBE5]">
            <svg class="mx-auto" width="84" height="12" viewBox="0 0 84 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M84 6L74 0.226497V11.7735L84 6ZM0 7H75V5H0V7Z" fill="white"/>
            </svg>
        </td>
        <td class="px-3 py-2 text-start truncate text-sm w-[25.00%] text-green-500">
            <div v-if="showReceiver">{{ receiverBalance.label }}</div>
            <div v-else class="px-3 py-2 truncate text-start text-sm w-[70%] bg-gray-500 animate-pulse rounded-full"></div>
        </td>
        <td class="px-3 py-2 text-start text-sm w-[15%] text-[#91CBE5]">
            {{ transaction.amount.toFixed(2) }}
        </td>
        <td class="px-3 py-2 text-end text-sm w-[5.00%] text-green-500">
            <!-- receiver -->
        </td>
        <td class="px-3 py-2 text-start text- flex justify-end">
            <img src="@/assets/icons/invoice_icon.svg" class="cursor-pointer mr-[17px]" @click="fullView = !fullView">
        </td>
    </tr>
    <tr v-if="fullView">
        <td class="text-white" colspan="6">Коментар: {{ transaction.label }}</td>
    </tr>
    <tr v-if="fullView">
        <td colspan="8">
            <div class="w-full h-px border-b-[0.6px] border-gray-100"></div>
        </td>
    </tr>
</template>

<script>
    import { getBalanceObject } from '@/services/apiRequests'
    import { ref, onMounted, watch, computed, onUpdated } from 'vue'
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'transaction-row',

        props: {
            transaction: {
                type: Object,
                required: true
            }
        },

        emits: ['addRecieverName', 'addRecieverStatus'],

        setup(props, { emit }) {
            const fullView = ref(false)
            const senderBalance = ref({})
            const receiverBalance = ref({})

            const showSender = ref(false)
            const showReceiver = ref(false)

            watch(senderBalance, () => {
                showSender.value = true
            })

            watch(receiverBalance, () => {
                showReceiver.value = true
            })

            onMounted(async () => {
                const senderBalanceObject = await getBalanceObject(props.transaction.sender_balance_id)
                senderBalance.value = senderBalanceObject.balance
                console.log(senderBalance.value)
                const receiverBalanceObject = await getBalanceObject(props.transaction.receiver_balance_id)
                receiverBalance.value = receiverBalanceObject.balance
                console.log(receiverBalance.value.label)
            })

            return {
                fullView,
                senderBalance,
                receiverBalance,
                showSender,
                showReceiver
            }
        },

        components: {
            Skeletor
        }
    }
</script>

<style lang="scss" scoped>

    .vue-skeletor {
        background-color: #ccc;
        animation: shimmer 1.5s infinite;
        animation-duration: 2s; 
        animation-timing-function: ease-in-out;
    }

    /* Default keyframes used in skeletor */
    @keyframes shimmer {
        0% {
            opacity: .5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: .5;
        }
    }

</style>