<template>
    <div class="bg-white rounded-[10px] flex flex-col items-center justify-center px-2 py-3">
        <div>
            {{ $t('trainersBlock.saveChanges') }}?
        </div>
        <div class="flex w-full justify-between">
            <button @click="$emit('confirmUpdate')" class="bg-[#91CBE5] text-[#142552] rounded-[10px] px-[20px] py-[10px] mt-[10px]">
                {{ $t('yes') }}
            </button>
            <button @click="$emit('close')" class="bg-[#DE3A73] text-[#142552] rounded-[10px] px-[20px] py-[10px] mt-[10px]">
                {{ $t('no') }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'confirm-update-form',
    }
</script>
