<template>
    <div class="balance-card-container">
        <div class="title">{{ title }}</div>
        <div v-if="loading" class="skeletor"></div>
        <div v-else class="value value-color overflow-x-auto">
            <div class="w-full"><span class="currency">₴</span> <span>{{ formatNumber(+balance) }}</span></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'balance-card',

        setup() {

            const formatNumber = (number) => {
                const parts = number.toFixed(2).toString().split('.');
                const integerPart = parts[0];
                const decimalPart = parts[1];

                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                const formattedNumber = formattedInteger + '.' + decimalPart;

                return formattedNumber;
            }

            return {
                formatNumber
            }
        },

        props: {
            balance: {
                type: Number,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            },
            valueColor: {
                type: String,
                default: '#9FA2B4'
            }
        }
    }
</script>

<style lang="scss" scoped>

    .value-color {
        color: v-bind(valueColor);
    }

    .balance-card-container {
        @apply bg-[#28375F] border-[#D9D9D9] border-[1px] border-opacity-10 text-center px-[10px] pt-[9px] pb-[7px] rounded-[10px] ;
    }
    .title {
        @apply text-[#9FA2B4];
    }

    .skeletor {
        @apply rounded-full bg-gray-400 animate-pulse w-[70%] mx-auto my-[7px];
    }

    .value {
        @apply font-bold mx-auto truncate;
    }

    @media (min-width: 1410px) and (max-width: 8000px) {
        .title {
            @apply text-[1em];
        }

        .skeletor {
            @apply h-[37px];
        }

        .value {
            @apply text-[37px];
        }

        .currency {
            @apply text-[18px];
        }
    }

    @media (min-width: 0px) and (max-width: 1410px) {
        .title {
            @apply text-[0.8em];
        }

        .skeletor {
            @apply h-[27px];
        }

        .value {
            @apply text-[27px];
        }

        .currency {
            @apply text-[13px];
        }
    }

</style>