<template>
  <div class="chart-container">
    <svg ref="svg" :height="height" :width="width">
      <g class="chart">
        <template v-for="(bar, i) in data">
          <rect
            :x="i * (barWidth + spacing)"
            :y="height - calculateBarHeight(bar) - marginBottom - 5"
            :width="barWidth"
            :height="calculateBarHeight(bar)"
            :fill="barColor"
          />
          <text
            :x="i * (barWidth + spacing) + barWidth / 2"
            :y="height - marginBottom + 10"
            text-anchor="middle"
            font-size="12"
            :fill="barColor"
          >
            {{ lables[i] }}
          </text>
          <text
            :x="i * (barWidth + spacing) + barWidth / 2"
            :y="height - calculateBarHeight(bar) - marginBottom - 10"
            text-anchor="middle"
            font-size="12"
            font-weight="bold"
            :fill="barColor"
          >
            {{ formatNumber(bar) }}
          </text>
        </template>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BarChart',

  props: {
    data: {
      type: Array,
      required: true
    },
    barColor: {
      type: String,
      default: '#00bfff'
    },
    lables: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 225
    },
    width: {
      type: Number,
      default: 920
    }
  },

  computed: {
    barWidth() {
      return 39; // Измените на свое усмотрение
    },
    spacing() {
      return 32; // Измените на свое усмотрение
    },
    marginBottom() {
      return 65; // Измените на свое усмотрение
    }
  },

  methods: {
    formatNumber(number) {
      const parts = number.toFixed(2).toString().split('.');
      const integerPart = parts[0];
      const decimalPart = parts[1];

      const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      const formattedNumber = formattedInteger + '.' + decimalPart;

      return formattedNumber;
    },

    calculateBarHeight(bar) {
      const max = Math.max(...this.data) > 0 ? Math.max(...this.data) : 5;
      const currentBarPercentFromFull = (100 * bar) / max;
      return (bar > 0 ? (this.height - this.marginBottom - 40) * (currentBarPercentFromFull / 100) : 1);
    },

    drawChart() {
      // Этот метод больше не нужен, так как мы используем SVG
    }
  }
};
</script>

<style scoped>

.chart {
  font-family: Arial, sans-serif;
}
</style>
