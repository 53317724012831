<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#142552] w-[400px] h-[67px] rounded-[10px] border-[1px] border-[#303061]">
        <div class="w-full text-center mt-[10px]">
            <div class="flex text-center items-center py-[4px] bg-[#91CBE5] w-[95%] rounded-[3px] mx-auto">
                <div class="block-title text-[11px] font-bold text-[#142552] text-center mx-auto w-[60%]">{{ $t('trainersBlock.trainerCard.loginData.title') }}</div>
            </div>
        </div>

        <div class="mx-[15px] text-[#91CBE5] flex items-end justify-between mt-[2px]">
            <div class="flex justify-between w-[40%] items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input :value="login" @change="$emit('update:login', $event.target.value)" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" :placeholder="$t('trainersBlock.trainerCard.loginData.login_placeholder')">
                </div>
                <div class="text-[#91CBE5] font-normal mt-[4px] opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.loginData.login') }}
                </div>
            </div>

            <div class="flex justify-between w-[40%] items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input :value="password" @change="$emit('update:password', $event.target.value)" class="text-[#91CBE5] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" :placeholder="$t('trainersBlock.trainerCard.loginData.password_placeholder')">
                </div>
                <div class="text-[#91CBE5] mt-[4px] font-normal opacity-90 text-[11px]">
                    {{ $t('trainersBlock.trainerCard.loginData.password') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataInput from '../ui/DataInput.vue'

    export default {
        name: 'auth-data-card',

        props: {
            login: {
                type: String,
                default: ''
            },
            password: {
                type: String,
                default: ''
            },
        },

        components: {
            DataInput
        }
    }
</script>
