<template>
    <div style="background-color: rgba(17, 32, 77, .35)" class="backdrop-blur-[7px] fixed w-full z-40 h-full top-0"></div>
    <div v-if="loadingInWindow" style="background-color: rgba(17, 32, 77, .35)" class="fixed w-full z-[70] h-full top-0"></div>
    <div v-if="loadingInWindow" class="z-[90] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#de3a73] border-t-transparent absolute top-[50%] left-[50%] ml-[-175px] mt-[-80px]"></div>
    </div>
    <div v-if="loading" class="z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#de3a73] border-t-transparent absolute top-[50%] left-[50%] ml-[-175px] mt-[-80px]"></div>
    </div>
    <div
        style="box-shadow: 4px 4px 4px 0px #00000040;"
        :class="{
            'scale-[90%]' : screenWidth <= 1600,
        }"   
        class="bg-[#11204D] border-[1px] border-[#303061] z-50 ml-[100px] rounded-[10px] w-[512px] px-[17px] py-[27px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="flex justify-center">
            <img src="@/assets/logo.svg" class="w-[56px]">
        </div>
        <div class="text-[18px] text-center mt-[10px] text-white font-semibold">
            Фільтри витрат
        </div>

        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    дата від
                </div>
                <div class="mt-[3px]">
                    <input 
                        v-model="proccessFilters.date.from"
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                        type="date" 
                        name="name"
                    >
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    дата до
                </div>
                <div class="mt-[3px]">
                    <input 
                        v-model="proccessFilters.date.to"
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                        type="date" 
                        name="name"
                    >
                </div>
            </div>
        </div>

        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    категорія витрати
                </div>
                <div class="mt-[3px]">
                    <select v-model="selectedCategory" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option class="bg-[#11204D] text-white" value="all">всі</option>
                        <option v-for="category in allCategories" class="bg-[#11204D] text-white" :value="`${category.id}|${category.name}`">{{ category.name }}</option>
                    </select>
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    підкатегорія витрати
                </div>
                <div class="mt-[3px]">
                    <select v-model="selectedSubcategory" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option class="bg-[#11204D] text-white" value="all">всі</option>
                        <option v-for="subcategory in allSubcategories" class="bg-[#11204D] text-white" :value="`${subcategory.id}|${subcategory.name}`">{{ subcategory.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    джерело витрати
                </div>
                <div class="mt-[3px]">
                    <select v-model="proccessFilters.type" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <!-- <option v-for="category in allCategories" class="bg-[#11204D] text-white" :value="`${category.id}|${category.name}`">{{ category.name }}</option> -->
                        <option class="bg-[#11204D] text-white" value="all">всі</option>
                        <option class="bg-[#11204D] text-white" value="cash">готівка</option>
                        <option class="bg-[#11204D] text-white" value="cashless">безготівка</option>
                    </select>
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    користувач
                </div>
                <div class="mt-[3px]">
                    <select v-model="proccessFilters.creator_name" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <!-- <option v-for="subcategory in allSubcategories" class="bg-[#11204D] text-white" :value="`${subcategory.id}|${subcategory.name}`">{{ subcategory.name }}</option> -->
                        <option class="bg-[#11204D] text-white" value="all">всі</option>
                        <option v-for="admin in allAdmins" class="bg-[#11204D] text-white" :value="admin.admin_name + ' ' + admin.admin_surname">{{ admin.admin_name + ' ' + admin.admin_surname }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="text-[18px] text-center mt-[20px] text-white font-semibold">
            Сортування витрат
        </div>


        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    по даті
                </div>
                <div class="mt-[3px]">
                    <select 
                        :value="proccessFilters.sort_by_date" 
                        @change="e => {proccessFilters.sort_by_date = e.target.value; proccessFilters.sort_by_amount = ''}" 
                        class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none"
                    >
                        <!-- <option v-for="category in allCategories" class="bg-[#11204D] text-white" :value="`${category.id}|${category.name}`">{{ category.name }}</option> -->
                        <option class="bg-[#11204D] text-white" value="">відсутнє</option>
                        <option class="bg-[#11204D] text-white" value="desc">від більшої до меншої</option>
                        <option class="bg-[#11204D] text-white" value="asc">від меншої до більшої</option>
                    </select>
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    по сумі
                </div>
                <div class="mt-[3px]">
                    <select
                        :value="proccessFilters.sort_by_amount" 
                        @change="e => {proccessFilters.sort_by_amount = e.target.value; proccessFilters.sort_by_date = ''}"  
                        class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none"
                    >
                        <!-- <option v-for="category in allCategories" class="bg-[#11204D] text-white" :value="`${category.id}|${category.name}`">{{ category.name }}</option> -->
                        <option class="bg-[#11204D] text-white" value="">відсутнє</option>
                        <option class="bg-[#11204D] text-white" value="desc">від більшої до меншої</option>
                        <option class="bg-[#11204D] text-white" value="asc">від меншої до більшої</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="w-full mt-[25px]">
            <div class="w-full flex justify-center">
                <button @click="updateFilters" class="bg-[#41BF74] text-white w-[260px] mx-auto font-medium h-[28px] rounded-[5px]">
                    Застосувати
                </button>
            </div>
            <div class="w-full flex justify-center mt-[10px]">
                <button @click="$emit('exportExcel', filters)" class="flex bg-[#41BF74] items-center justify-center text-white w-[260px] mx-auto font-medium h-[28px] rounded-[5px]">
                    <img src="@/assets/icons/excel_icon.svg" class="h-[16px] mr-[5px]">
                    <div>{{ $t('paymentsBlock.filtersForm.exportExcel') }}</div>
                </button>
            </div>
            <div class="w-full mt-[10px] flex justify-center">
                <button @click="$emit('close')" class="w-[260px] bg-[#91CBE5] font-medium h-[28px] mx-auto rounded-[5px] text-[#11204D]">
                    Відмінити
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import { getAllOutlayCategories, getSubcategoriesByCategoryId, getAllAdmins } from '@/services/apiRequests'
    
    export default {
        name: 'club-outlay-filter',

        props: {
            filters: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const loading = ref(true)
            const loadingInWindow = ref(false)

            const allCategories = ref([])
            const allSubcategories = ref([])
            const allAdmins = ref([])

            const selectedCategory = ref('all')
            const selectedSubcategory = ref('all')

            // # 'category_id' : 'all',
            // # 'subcategory_id' : 'all',
            // # 'creator_name': 'all',
            // # 'type': 'all',
            // # 'date': {
            // #     'from': datetime.datetime(2021, 1, 1),
            // #     'to': datetime.datetime.now()
            // # },
            // # 'amount': 'desc'

            const proccessFilters = ref(props.filters)

            const screenWidth = ref(window.innerWidth)
            watch(window.innerWidth, () => {
                screenWidth.value = window.innerWidth
            })

            onMounted(async () => {
                const allCategoriesRequest = await getAllOutlayCategories()
                allCategories.value = allCategoriesRequest

                const subcategoriesRequest = await getSubcategoriesByCategoryId(allCategoriesRequest[0].id)
                allSubcategories.value = subcategoriesRequest

                const allAdminsRequest = await getAllAdmins()
                console.log('allAdminsRequest ------- ', allAdminsRequest)
                allAdmins.value = allAdminsRequest

                loading.value = false
            })

            watch(selectedCategory, async() => {
                loadingInWindow.value = true
                const categoryObjectArray = selectedCategory.value.split('|')
                const categoryId = categoryObjectArray[0]

                const subcategoriesRequest = await getSubcategoriesByCategoryId(categoryId)
                console.log('selectedCategory.value ------- ', subcategoriesRequest)
                allSubcategories.value = subcategoriesRequest
                selectedSubcategory.value = 'all'
                loadingInWindow.value = false
            })

            const updateFilters = () => {
                const categoryObjectArray = selectedCategory.value.split('|')
                const categoryId = categoryObjectArray[0]

                const subcategoryObjectArray = selectedSubcategory.value.split('|')
                const subcategoryId = subcategoryObjectArray[0]

                proccessFilters.value.category_id = categoryId
                proccessFilters.value.subcategory_id = subcategoryId
                console.log('proccessFilters.value ------- ', proccessFilters.value)
                emit('updateFilters', proccessFilters.value)
            }


            return {
                loading,
                loadingInWindow,
                allCategories,
                allSubcategories,
                selectedCategory,
                selectedSubcategory,
                proccessFilters,
                allAdmins,
                updateFilters,
                screenWidth
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>