const en = {
    sidebar: {
        dashboard: 'Dashboard',
        groups: 'Groups',
        trainers: 'Trainers',
        students: 'Students',
        admins: 'Admins',
        locations: 'Locations',
        calendar: 'Calendar',
        finance: 'Finance',
        settings: 'Settings',
        incomes: 'Incomes',
        outlays: 'Accrual',
        transactions: 'Transactions',
        outlays_categories: 'Outlays categories',
        club_outlays: 'Outlays',
    },

    dashboard: {
        balanceCards: {
            allIncomes: 'all incomes',
            cashIncomes: 'cash',
            cashlessIncomes: 'non-cash',
            investIncomes: 'investments'
        },
        statisticCards: {
            averagePayment: 'average payment',
            coachesCash: 'coaches cash',
            totalWriteoffs: 'total write-offs',
            numberOfWriteoffs: 'number of write-offs'
        },
        incomesBySources: 'INCOMES BY SOURCES',
        incomesByGroups: 'INCOMES BY GROUPS',
        allIncomes: 'all incomes',
        outlaysTitle: 'OUTLAYS',
        earningsTitle: 'EARNINGS',
        wholeYear: 'FOR THE WHOLE YEAR',

        earnings: {
            revenue: 'revenue',
            earnings: 'earnings',
            outlays: 'outlays',
        },

        statisticTitle: 'QUANTITATIVE INDICATORS',
        statisticBlock: {
            trainers: 'trainers',
            groups: 'groups',
            students: 'students'
        },
        trainerAnalytics: 'TRAINER ANALYTICS',
        trainerAnalyticstTable: {
            photo: 'photo',
            trainer: 'trainer',
            groups: 'groups',
            players: 'players',
            totalCompletedTrainings: 'total completed trainings',
            totalIncomesByTrainer: 'total incomes by trainer',
        },
        tips: {
            cashboxInfo: 'The CASHBOX block displays actual data regarding the available funds in the club as of the current moment.',
            averagePayment: 'Average payment - the sum of payments in the current month divided by the number of payments.',
            trainerCash: 'Cash with coaches - the total cash on hand with all the coaches.',
            outlaysSum: 'Total deductions - shows how much money was received for the training sessions conducted this month, excluding advance payments.',
            outlaysCount: 'Number of deductions indicates how many students attended and paid for training in the current month.',
            incomesBySources: 'The INCOME BY SOURCES diagram demonstrates the dynamics of funds received from each source, as well as the total (highlighted in red) from all sources.',
            circleDiagram: 'The pie chart shows the distribution in percentages by income types.'
        }
    },

    groupsPage: {
        table: {
            groupName: 'Group',
            trainer: 'Trainer',
            studentsCount: 'Students count',
        },

        groupCard: {
            players: 'Players',
            newGroupName: 'group name',
            yearRange: 'range of students\' birth years',
            selectTrainer: 'select a coach from the list',
            group: 'Group',
            payable: 'paid',
            free: 'free',
            studentsManage: 'Manage students',
            infoAboutTrainer: 'information about the coach',
            trainerNotSelected: 'Not selected',
            formTitle: 'Creating a group and appointing a coach',
            createButton: 'Create',
            returnButton: 'Cancel',
            selectTrainerPlaceholder: 'Select a coach',
        },

        SelectStudentsForm: {
            saveGroupPlayers: 'Do you want to save the composition of the group',
            playersInClub: 'Players in the group',
            student: 'Student',
            group: 'Group',
            age: 'Age',
            from: 'from',
            to: 'to',
            page: 'Page',
            studentsInGroup: 'Students in the group',
            close: 'Close',
            trainerName: 'Trainer name',
            deleteGroup: 'Do you really want to delete the group'
        }
    },

    shortMonthes: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
    },

    fullMonthes: {
        jan: 'january',
        feb: 'february',
        mar: 'march',
        apr: 'april',
        may: 'may',
        jun: 'june',
        jul: 'july',
        aug: 'august',
        sep: 'september',
        oct: 'october',
        nov: 'november',
        dec: 'december'
    },

    menuBar: {
        addButton: 'Add',
        searchPlaceholder: 'Search',
        page: 'Page',
    },

    trainersBlock: {
        table: {
            trainerName: 'Coach',
            groupsCount: 'Groups',
            playersCount: 'Number of active students',
            status: 'Status'
        },
        line: {
            students: 'students',
            trainers: 'trainers', 
        },
        saveChanges: 'Do you want to save the changes in the trainer card',
        deleteTrainerConfirm: 'You really want to delete the trainer',

        trainerCard: {
            main: {
                main_placeholder: 'Lionel Messi',
                surname: 'surname',
                surname_placeholder: 'Ivanenko',
                name: 'name',
                name_placeholder: 'Ivan',
                patronymic: 'patronymic',
                patronymic_placeholder: 'Ivanovich',
                phone: 'phone',
                phone_placeholder: '+380000000000',
                birthday: 'birthday',
                email: 'email',
                email_placeholder: 'Email',
                nationality: 'nationality',
                nationality_placeholder: 'Ukrainian',
            },
            loginData: {
                title: 'LOGIN DATA',
                login: 'login',
                login_placeholder: '000000000',
                password: 'password',
                password_placeholder: 'password',
            },
            parametersData: {
                title: 'PARAMETERS',

                height: 'height',
                height_placeholder: '0.00',
                weight: 'weight',
                weight_placeholder: '0.00',
                chest_girth: 'chest girth',
                chest_girth_placeholder: '0.00',
                main_leg: 'leg',
                main_leg_placeholder: 'Left',
                leg_size: 'leg size',
                leg_size_placeholder: '0',
                club_start: 'start',
                club_start_placeholder: '01.01.2020',
            },
            documentsData: {
                title: 'DOCUMENTS',

                eng_name: 'name',
                eng_name_placeholder: 'Ivan',
                eng_surname: 'surname',
                eng_surname_placeholder: 'Ivanenko',
                end_passport_date: 'passport expiration date',
                passport_number: 'passport',
                passport_number_placeholder: 'Passport number',
                id_code: 'ID code',
                id_code_placeholder: '000000000000',
                ukrainian_passport_number: 'Ukrainian passport',
                ukrainian_passport_number_placeholder: 'Passport number',
                issue_institute: 'issued by',
                issue_institute_placeholder: 'institute name',
                date_issue: 'issue date',
            },
            financeData: {
                title: 'FINANCE',

                student_balance: 'student balance',
                service_balance: 'service balance',
                payment: 'payments',
                search: 'see',
                transactions: 'transactions',
                no_payments: 'no payments',
                no_transactions: 'no transactions',
            }
        },
    
    },

    studentBlock: {
        table: {
            name: 'Name',
            group: 'Group',
            phone: 'Phone',
            balance: 'Balance',
            status: 'Status',
        },

        studentsNotFound: 'Students not found',
        deleteStudent: 'Do you really want to delete the student',
        studentCard: {
            main: {
                group: 'group',
                age: 'age',
                amplua: 'amplua',
                number: 'number',
                main_placeholder: 'Lionel Messi',
                surname: 'surname',
                surname_placeholder: 'Ivanenko',
                name: 'name',
                name_placeholder: 'Ivan',
                patronymic: 'patronymic',
                patronymic_placeholder: 'Ivanovich',
                phone: 'phone',
                phone_placeholder: '+380000000000',
                birthday: 'birthday',
                email: 'email',
                email_placeholder: 'Email',
                nationality: 'nationality',
                nationality_placeholder: 'Ukrainian',
            },
            parametersData: {
                title: 'PARAMETERS',
    
                login: 'login',
                login_placeholder: '000000000',
                password: 'password',
                password_placeholder: 'password',
    
                height: 'height',
                height_placeholder: '0.00',
                weight: 'weight',
                weight_placeholder: '0.00',
                chest_girth: 'chest girth',
                chest_girth_placeholder: '0.00',
                main_leg: 'leg',
                main_leg_placeholder: 'Left',
                leg_size: 'leg size',
                leg_size_placeholder: '0',
                club_start: 'start in club',
                club_start_placeholder: '01.01.2020',
                amplua: 'amplua',
                amplua_placeholder: 'Forward',
                player_number: 'player number',
                player_number_placeholder: '0',
            },
    
            documentsData: {
                title: 'DOCUMENTS',
    
                eng_name: 'name',
                eng_name_placeholder: 'Ivan',
                eng_surname: 'surname',
                eng_surname_placeholder: 'Ivanenko',
                end_passport_date: 'passport expiration date',
                passport_number: 'passport',
                passport_number_placeholder: 'Passport number',
                id_code: 'ID code',
                id_code_placeholder: '000000000000',
                ukrainian_passport_number: 'Ukrainian passport',
                ukrainian_passport_number_placeholder: 'Passport number',
                issue_institute: 'issued by',
                issue_institute_placeholder: 'institute name',
                date_issue: 'issue date',
                attorney: 'Attorney',
                documents_photo: 'Documents photo',
                patron_person: 'patron person',
                patron_person_number: 'Phone',
                patron_person_name: 'Name and surname',
            },
    
            statisticsData: {
                title: 'STATISTICS',

                count_of_trainings: 'trainings',
                count_of_attendings: 'attendings',

                attend_training: 'attended',
                dont_attend_training: 'missed',
                planed_training: 'planned',
            },

            financeData: {
                title: 'FINANCE',

                student_balance: 'student balance',
                service_balance: 'service balance',
                payment: 'payments',
                outlays: 'accrual',
                search: 'see',
                transactions: 'transactions',
                no_payments: 'no payments',
                no_transactions: 'no transactions',
            }

        },
    },

    adminsBlock: {
        table: {
            name: 'Name',
            cash_balance: 'Cash balance',
            investment_balance: 'Investment balance',
        },
        line: {
            admin_cash: 'admin\'s cash',
            admin_invest: 'admin\'s investments',
        }
    },

    locationsBlock: {
        table: {
            location_name: 'Location name and address',
            short_name: 'Short name',
            description: 'Description',
        },

        line: {
            locations: 'locations',
        },

        locationCard: {
            name: 'location name',
            short_name: 'short location name',
            address: 'location address',
            map_url: 'map url',
            description: 'description',
            photo: 'location photo',
            newLocationTitle: 'New location for events',
            photoUploadPrompt: 'Upload location photo',
        },

        locationsNotFound: 'Locations not found',
        deleteLocationConfirm: 'Do you really want to delete the location',
    },

    calendarBlock: {
        fullTrainingCard: {
            comment: 'Training comment',
            showInMap: 'Show in map',
        },

        eventTypes: {
            training: 'Training',
            match: 'Competition',
            gym: 'Gym',
        },
        
        trainingForm: {
            notSelect: 'Not selected',
            recursiveTrainings: 'Recursive training',
            group: 'group',
            location: 'location',
            comment: 'Training comment',
        },

        saveChanges: 'Do you want to save the changes in the training card',
    },

    userStatuses: {
        active: 'Active',
        debtor: 'Debtor',
        achive: 'Archive'
    },

    paymentsBlock: {
        buttons: {
            getPaymentByStudent: 'Add payment by student',
            getInvest: 'Add investment',
            getPayment: 'Add payment',
            filters: 'Filters',
        },

        table: {
            receiver: 'Receiver',
            receiverStatus: 'Receiver status',
            transactionType: 'Transaction type',
            summ: 'Amount',
            date: 'Date',
            time: 'Time',
        },

        receiverStatuses: {
            student: 'Student',
            trainer: 'Trainer',
            service: 'Service',  
        },

        transactionTypes: {
            virtual: 'Virtual',
            real: 'Real',
        },

        row: {
            balanceName: 'Balance',
            balanceID: 'Balance ID',
            transactionComment: 'Transaction comment',
            transactionID: 'Transaction ID',
            embedTransaction: 'Embed transaction', 
            additionalFiles: 'Additional files', 
        },

        addPaymentByStudentForm: {
            title: 'Add payment',
            addToServiceBalance: 'add to service balance',
            studentBalance: 'student balance',
            transactionSumm: 'transaction amount',
            registrationDate: 'transaction date',
            additionalFiles: 'Additional files',
            receiveButton: 'Add',
        },

        addInvestForm: {
            title: 'Add investment',
            investSumm: 'investment amount',
            comment: 'transaction comment',
            additionalFiles: 'Additional files',
            receiveButton: 'Add',
        },

        getPayment: {
            title: 'Add payment',
            addToServiceBalance: 'add to service balance',
            transactionSumm: 'transaction amount',
            transactionComment: 'transaction comment',
            additionalFiles: 'Additional files',
            receiveButton: 'Add', 
        },

        filtersForm: {
            title: 'Filters',
            receiverStatus: 'receiver status',
            transactionType: 'transaction type',
            transactionOrigin: 'transaction origin',
            transactionAmount: 'transaction amount',
            from: 'From',
            to: 'To',
            transactionDate: 'transaction date',
            applyButton: 'Apply',
            exportExcel: 'Export to Excel',
        }
    },

    outlaysBlock: {
        table: {
            sender: 'Sender',
            senderStatus: 'Sender status',
            transactioType: 'Transaction type',
            amount: 'Amount',
            date: 'Date',
            time: 'Time',
        },

        mainBar: {
            page: 'Page',
            filters: 'Filters',
        },

        filtersForm: {
            title: 'Accural filters',
            receiverStatus: 'receiver status',
            transactionType: 'transaction type',
            transactionOrigin: 'transaction origin',
            transactionAmount: 'transaction amount',
            from: 'From',
            to: 'To',
            transactionDate: 'transaction date',
            applyButton: 'Apply',
        },
        row: {
            balanceName: 'Balance',
            balanceID: 'Balance ID',
            transactionComment: 'Transaction comment',
            transactionID: 'Transaction ID', 
        },
    },

    transactionBlock: {
        table: {
            date: 'Date',
            sender: 'Sender', 
            receiver: 'Receiver', 
            amount: 'Amount',
        },
        mainBar: {
            page: 'Page',
            filters: 'Filters',
            createTxButton: 'Create transaction',
        },
        newTransactionForm: {
            title: 'New transaction',
            sender: 'from balance',
            receiver: 'to balance',
            amount: 'balance amount',
            comment: 'comment',
            additionalFiles: 'Additional files',
            createButton: 'Apply',
        }
    },

    outlaysCategoriesBlock: {
        table: {
            category: 'Category',
            subcategory: 'Subcategory',
            monthAmount: 'Amount per month',
        },
        newCategoryPlaceholder: 'New category',
        newSubcategoryPlaceholder: 'New subcategory',
        allChangesSaved: 'All changes saved',
        changesSaving: 'Changes saving',
    },

    settingsBlock: {
        allInfo: {
            title: 'All information',
            uaClubName: 'Club name in Ukrainian',
            engClubName: 'Club name in English',
            creationDate: 'Club creation date',
            legalAddress: 'Legal address',
            postAddress: 'Postal address',
            mapUrl: 'Map URL',
        },
        contacts: {
            title: 'Contacts',
            mainPhone: 'Main phone',
            secondPhone: 'Second phone',
            officialMail: 'Official email',
            facebookPage: 'Facebook page',
            webPage: 'Web page',
            instagramPage: 'Instagram page',
        },
        bankBlock: {
            title: 'Bank details',
            bankName: 'Bank name',
            receiverCode: 'Receiver code',
            receiverAccount: 'Receiver account',
            paymentSystemIntegration: 'Payment system integration',
            ERDPOU: 'ERDPOU code',
        }
    },

    see: 'See',
    club_name: 'FC MINAJ',
    noData: 'No data',
    yes: 'Yes',
    no: 'No',
    close: 'Close',
    add: 'Add',
    change: 'Change',
    delete: 'Delete',
    download: 'Download',
    changesWillBeLoss: 'Your changes will be lost if you do not save them.',
    save: 'Save',
    dontSave: 'Don\'t save',
    cancel: 'Cancel',
}

export default en