<template>
    <div class="border-[1px] border-[#303061] w-full px-[21px] py-[17px] rounded-[10px] mt-[27px]">
        <div class="uppercase bg-[#ABD3EC] py-[4px] text-[13px] font-bold text-center rounded-[4px]">
            {{ $t('settingsBlock.contacts.title') }}
        </div>

        <div class="flex w-full justify-between">
            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        {{ $t('settingsBlock.contacts.mainPhone') }}
                    </div>
                    <div class="mt-[3px]">
                        <input
                            :value="clubPhone"
                            @change="$emit('update:clubPhone', $event.target.value)" 
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        {{ $t('settingsBlock.contacts.secondPhone') }}
                    </div>
                    <div class="mt-[3px]">
                        <input
                            :value="clubAdditionalPhone"
                            @change="$emit('update:clubAdditionalPhone', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        {{ $t('settingsBlock.contacts.officialMail') }}
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="clubEmail"
                            @change="$emit('update:clubEmail', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        {{ $t('settingsBlock.contacts.facebookPage') }}
                    </div>
                    <div class="mt-[3px]">
                        <input
                            :value="facebookPage"
                            @change="$emit('update:facebookPage', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        {{ $t('settingsBlock.contacts.webPage') }}
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="webPage"
                            @change="$emit('update:webPage', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        {{ $t('settingsBlock.contacts.instagramPage') }}
                    </div>
                    <div class="mt-[3px]">
                        <input
                            :value="instagramPage"
                            @change="$emit('update:instagramPage', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'

    export default {
        name: 'Contacts',

        props: {
            clubPhone: {
                type: String,
                default: ''
            },
            clubAdditionalPhone: {
                type: String,
                default: ''
            },
            clubEmail: {
                type: String,
                default: ''
            },
            facebookPage: {
                type: String,
                default: ''
            },
            webPage: {
                type: String,
                default: ''
            },
            instagramPage: {
                type: String,
                default: ''
            },
        },

        setup() {

        }
    }
</script>

<style lang="scss" scoped>

</style>