<template>
    <CategoryElement
        :categoryName="category.name"
        :categoryMonthSum="category.monthSum"
        :categoryId="category.id"
        :categoryNumber="listNumber"

        @delete-category="categoryId => $emit('delete-category', categoryId)"

        @start-loading="$emit('start-loading')"
        @end-loading="$emit('end-loading')"

        @updateCategoryName="newCategoryName => $emit('updateCategoryName', newCategoryName)"
    />
    <SubcategoryElement
        v-for="subcategory in subcategories"
        :subcategoryName="subcategory.name"
        :subcategoryMonthSum="subcategory.monthSum"
        :subcategoryId="subcategory.id" 
        :categoryId="category.id"
        :key="subcategoriesKey"

        @delete-subcategory="deleteSubcategory"
        @update-subcategory="newSubcategory => $emit('update-subcategory', newSubcategory)"
    />
    <tr class="text-white border-b-[1px] border-[#303061]">
        <td class="w-[4%] text-start"></td>
        <td class="w-[25%] text-start"></td>
        <td class="w-[20%] py-1.5 text-center text-green-500 flex justify-start items-center">
            <input v-model="newSubcategory" type="text" class="text-[14px] bg-transparent text-[#F8FF82] placeholder:text-white placeholder:opacity-40 relative outline-none" :placeholder="$t('outlaysCategoriesBlock.newSubcategoryPlaceholder') + '...'">
        </td>
        <td class="w-[15%] text-end text-[#F10265]"></td>
        <td class="w-[36%] h-full">
            <div v-if="newSubcategoryButtons" class="flex justify-end items-center">
                <img @click="addSubcategory" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img @click="newSubcategory = ''; newSubcategoryButtons = false" src="@/assets/icons/trash_icon.svg" class="cursor-pointer mr-[10px]">
            </div>
        </td>
    </tr>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import CategoryElement from './CategoryElement.vue'
    import SubcategoryElement from './SubcategoryElement.vue'

    export default {
        name: 'CategoryBlock',

        props: {
            category: {
                type: Object,
                default: () => {}
            },
            subcategories: {
                type: Array,
                default: () => []
            },
            listNumber: {
                type: Number,
                default: 0
            }
        },

        setup(props, { emit }) {
            const newSubcategory = ref('')
            const newSubcategoryButtons = ref(false)
            const subcategoriesKey = ref(uuid.v4())

            const addSubcategory = () => {
                emit('add-subcategory', newSubcategory.value)
                newSubcategory.value = ''
                newSubcategoryButtons.value = false
            }

            const deleteSubcategory = (subcategoryName) => {
                emit('delete-subcategory', subcategoryName)
            }

            watch(newSubcategory, (value) => {
                if (value.length > 0)
                    newSubcategoryButtons.value = true
            })

            watch(props.subcategories, () => {
                subcategoriesKey.value = uuid.v4()
            })

            return {
                newSubcategory,
                newSubcategoryButtons,
                addSubcategory,
                deleteSubcategory,
                subcategoriesKey
            }
        },

        components: {
            CategoryElement,
            SubcategoryElement
        },
    }
</script>

<style lang="scss" scoped>

</style>