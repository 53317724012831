import { createI18n } from 'vue-i18n'
import en from './en_locale'
import ua from './ua_locale'

const i18n = createI18n({
    locale: 'ua',
    allowComposition: true, // you need to specify that!
    messages: {
        ua,
        en
      }
  })
  

export default i18n