<template>
    <div @click="$emit('close')" style="background-color: rgba(17, 32, 77, .35)" class="backdrop-blur-[7px] fixed w-full z-40 h-full top-0 -mt-2"></div>
    <div class="bg-[#142552] rounded-[10px] z-50 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] border-b-[1px] border-[#303061]">
        <div class="px-[20px] py-[20px] w-[300px]">
            <div class="flex justify-center"><img src="../../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center text-[16px] text-white mb-[25px] mt-[10px]">
                Фільтри транзакцій
            </div>
            <div class="w-full">    
                <div class="text-center mb-[3px] text-[13px] text-[#91CBE5]">
                    Статус отримувача
                </div>
                <select v-model="filters.receiver_type" class="w-full rounded-[6px] text-[13px] bg-[#D9D9D9] text-[#000] py-[5px] pl-[5px] outline-none" name="receiver_type">
                    <option value="all" class="bg-[#D9D9D9]">Всі</option>
                    <option value="Учень" class="bg-[#D9D9D9]">Учень</option>
                    <option value="Тренер" class="bg-[#D9D9D9]">Тренер</option>
                    <option value="Сервіс" class="bg-[#D9D9D9]">Сервіс</option>
                </select>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-center mb-[3px] text-[13px] text-[#91CBE5]">
                    Тип транзакції
                </div>
                <select v-model="filters.transaction_type" class="w-full rounded-[6px] text-[13px] bg-[#D9D9D9] text-[#000] py-[5px] pl-[5px] outline-none" name="transaction_type">
                    <option value="all" class="bg-[#D9D9D9]">Всі</option>
                    <option value="Реальна" class="bg-[#D9D9D9]">Реальна</option>
                    <option value="Віртуальна" class="bg-[#D9D9D9]">Віртуальна</option>
                </select>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-center mb-[3px] text-[13px] text-[#91CBE5]">
                    Сума транзакції
                </div>
                <div class="flex justify-between">
                    <div class="rounded-[6px] flex px-[10px] text-[13px] py-[4px] bg-[#D9D9D9] text-[#000]">
                        <span>Від:</span>
                        <input v-model="filters.amount_from" class="px-[5px] input-hide-arrows w-[50px] bg-transparent text-[#000] outline-none" type="number" name="amount_from">
                        <span>грн.</span>
                    </div>
                    <div class="rounded-[6px] flex px-[10px] py-[4px] text-[13px] bg-[#D9D9D9] text-[#000]">
                        <span>До:</span>
                        <input v-model="filters.amount_to" class="input-hide-arrows px-[5px] w-[50px] bg-transparent text-[#000] outline-none" type="number" name="amount_to">
                        <span>грн.</span>
                    </div>
                </div>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-center mb-[3px] text-[13px] text-[#91CBE5]">
                    Дата транзакції
                </div>
                <div class="flex justify-between">
                    <div class="rounded-[6px] flex px-[10px] py-[4px] text-[13px] bg-[#D9D9D9] text-[#000] items-center">
                        <span>Від:</span>
                        <input v-model="filters.date_from" class="w-[80px] bg-transparent pl-[5px] outline-none" type="date" name="date_from">
                    </div>
                    <div class="rounded-[6px] flex px-[10px] py-[4px] text-[13px] bg-[#D9D9D9] text-[#000] items-center">
                        <span>До:</span>
                        <input v-model="filters.date_to" class="w-[80px] bg-transparent  pl-[5px] outline-none" type="date" name="date_to">
                    </div>
                </div>
            </div>
            <!-- <div v-if="filters.current_transaction_id" class="w-full mt-[20px]">
                <div class="text-center mb-[3px]">
                    ID транзакції
                </div>
                <input :value="filters.current_transaction_id" type="text" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none disabled:text-gray-500" name="transaction_id" disabled>
            </div>
            <div v-if="filters.current_balance_id" class="w-full mt-[20px]">
                <div class="text-center mb-[3px]">
                    ID балансу
                </div>
                <input :value="filters.current_balance_id" type="text" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none disabled:text-gray-500" name="transaction_id" disabled>
            </div> -->
            <button @click="$emit('setFilters', filters)" class="w-full rounded-[6px] bg-[#41BF74] text-[13px] text-white py-[4px] mt-[21px]">
                Застосувати
            </button>
            <button @click="$emit('clearFilters')" class="w-full rounded-[6px] bg-[#ABD3EC] text-[13px] text-[#142552] py-[4px] mt-[17px]">
                Скинути
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'

    export default {
        name: 'income-filter-popup',

        props: {
            filters: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props) {
            const filters = ref(Object.assign({}, props.filters))

            return {
                filters
            }
        }
    }
</script>

<style lang="scss" scoped>
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    @font-face {
        font-family: 'Fixel';
        src: url('../../../../assets/fonts/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    .window {
        font-family: 'Fixel';
    }
</style>