<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#142552] w-[400px] h-[305px] rounded-[10px] mt-[15px] pt-[10px] border-[1px] border-[#303061]">
        <div class="bg-[#ABD3EC] text-[13px] font-bold text-center rounded-[4px] mx-[14px] py-[2px]">
            {{ $t('studentBlock.studentCard.documentsData.title') }}
        </div>
        <image-viewer 
            class="absolute z-40"
            v-if="showImageViewer"
            :image="currentImage"
            :photoType="currentPhotoType"
            @close="showImageViewer = false"
            @deletePhoto="$emit('deletePhoto', {currentImage, currentPhotoType})"
        />
        <div class="mx-[14px]">
            <div class="flex justify-between items-center align-middle mb-[9px]">
                <div class="w-[30%]">
                    <input :value="nameEng" @change="$emit('update:nameEng', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.documentsData.eng_name_placeholder')" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-start text-[11px] w-[70%]">
                    {{ $t('studentBlock.studentCard.documentsData.eng_name') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[30%]">
                    <input :value="surnameEng" @change="$emit('update:surnameEng', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.documentsData.eng_surname_placeholder')" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-start text-[11px] w-[70%]">
                    {{ $t('studentBlock.studentCard.documentsData.eng_surname') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="flex items-center w-[50%]">
                    <div class="w-[60%]">
                        <input :value="passport" @change="$emit('update:passport', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.documentsData.passport_number_placeholder')" type="text">
                    </div>
                    <div class="font-normal text-[#ABD3EC80] opacity-90 text-start text-[11px] w-[20%]">
                        {{ $t('studentBlock.studentCard.documentsData.passport_number') }}
                    </div>
                </div>
                <div class="text-sm flex justify-end items-end mr-[5px]">
                    <img 
                        v-for="passportPhoto in passportPhotoArray"
                        @click="setImageToView(passportPhoto, 'passport')"
                        src="@/assets/icons/picture_icon.svg" 
                        class="mx-[2px] w-[16px] cursor-pointer"
                    >
                    <img 
                        @click="uploadPassportPhoto"
                        src="@/assets/icons/upload_icon_light.svg" 
                        class="w-[16px] ml-2 cursor-pointer"
                    >
                    <input 
                        @change="$emit('addPassportPhoto', $event.target.files[0])"
                        ref="passportFilesInput" 
                        type="file" 
                        class="hidden"
                    >
                </div>
            </div>


            <div class="flex justify-between items-center align-middle mb-[12px]">
                <div class="flex items-center w-[50%]">
                    <div class="w-[60%]">
                        <input :value="idNumber" @change="$emit('update:idNumber', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.documentsData.id_code_placeholder')" type="text">
                    </div>
                    <div class="font-normal text-[#ABD3EC80] opacity-90 text-start text-[11px] w-[21%]">
                        {{ $t('studentBlock.studentCard.documentsData.id_code') }}
                    </div>
                </div>
                <div class="text-sm flex justify-end items-end mr-[5px]">
                    <img 
                        v-for="idNumber in idNumberPhotoArray"
                        @click="setImageToView(idNumber, 'idNumber')"
                        src="@/assets/icons/picture_icon.svg" 
                        class="mx-[2px] w-[16px] cursor-pointer"
                    >
                    <img 
                        @click="uploadIdNumberPhoto"
                        src="@/assets/icons/upload_icon_light.svg" 
                        class="w-[16px] ml-2 cursor-pointer"
                    >
                    <input 
                        @change="$emit('addIdNumberPhoto', $event.target.files[0])"
                        ref="idNumberFilesInput" 
                        type="file" 
                        class="hidden"
                    >
                </div>
            </div>

            <div class="flex justify-between items-center align-middle mb-[12px]">
                <div class="w-[30%]">
                    <input :value="endPasspotDate" @change="endPasspotDate = $event.target.value" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.documentsData.id_code_placeholder')" type="date">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-start text-[11px] w-[70%]">
                    {{ $t('studentBlock.studentCard.documentsData.end_passport_date') }}
                </div>
            </div>

            <div class="flex justify-between items-center align-middle mb-[15px]">
                <div class="flex items-center w-[50%]">
                    <div class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60">
                        {{ $t('studentBlock.studentCard.documentsData.attorney') }}
                    </div>
                </div>
                <div class="text-sm flex justify-end items-end mr-[5px]">
                    <img 
                        v-for="attorney in attorneyFileArray" 
                        @click="setImageToView(attorney, 'attorney')"
                        src="@/assets/icons/picture_icon.svg" 
                        class="mx-[2px] w-[16px] cursor-pointer"
                    >
                    <img 
                        @click="uploadAttorneyPhoto"
                        src="@/assets/icons/upload_icon_light.svg" 
                        class="w-[16px] ml-2 cursor-pointer"
                    >
                    <input 
                        @change="$emit('addAttorneyPhoto', $event.target.files[0])"
                        ref="attorneyFilesInput" 
                        type="file" 
                        class="hidden"
                    >
                </div>
            </div>

            
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="flex items-center w-[50%]">
                    <div class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60">
                        {{ $t('studentBlock.studentCard.documentsData.documents_photo') }}
                    </div>
                </div>
                <div class="text-sm flex justify-end items-end mr-[5px]">
                    <img 
                        v-for="photoForPassport in photoForPassportArray" 
                        @click="setImageToView(photoForPassport, 'photo_for_passport')"
                        src="@/assets/icons/picture_icon.svg" 
                        class="mx-[2px] w-[16px] cursor-pointer"
                    >
                    <img 
                        @click="uploadPhotoForPassport"
                        src="@/assets/icons/upload_icon_light.svg" 
                        class="w-[16px] ml-2 cursor-pointer"
                    >
                    <input 
                        @change="$emit('addPhotoForPassport', $event.target.files[0])"
                        ref="photoForPassportInput" 
                        type="file" 
                        class="hidden"
                    >
                </div>
            </div>

            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[35%]">
                    <input :value="patronFirstName" @change="$emit('update:patronFirstName', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.documentsData.patron_person_name')" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-center text-[11px] w-[30%]">
                    {{ $t('studentBlock.studentCard.documentsData.patron_person') }}
                </div>
                <div class="w-[35%]">
                    <input :value="patronFirstPhone" @change="$emit('update:patronFirstPhone', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-full truncate text-end placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.documentsData.patron_person_number')" type="text">
                </div>
            </div>

            <!-- <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[35%]">
                    <input :value="patronSecondName" @change="$emit('update:patronSecondName', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ім'я та прізвище" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-center text-[11px] w-[30%]">
                    відпов. особа #2
                </div>
                <div class="w-[35%]">
                    <input :value="patronSecondPhone" @change="$emit('update:patronSecondPhone', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-full truncate text-end placeholder:opacity-60" placeholder="Телефон" type="text">
                </div>
            </div> -->

        </div>

    </div>
</template>

<script>
    import { ref, computed } from 'vue'

    import DataInput from '../ui/DataInput.vue'
    import ImageViewer from '../ui/ImageViewer.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'documentary-card',

        props: {
            nameEng: {
                type: String,
                default: ''
            },
            surnameEng: {
                type: String,
                default: ''
            },
            passport: {
                type: String,
                default: ''
            },
            passportPhotoArray: {
                type: Array,
                default: []
            },
            idNumber: {
                type: String,
                default: ''
            },
            idNumberPhotoArray: {
                type: Array,
                default: []
            },
            passportEndDate: {
                type: String,
                default: ''
            },
            patronFirstName: {
                type: String,
                default: ''
            },
            patronFirstPhone: {
                type: String,
                default: ''
            },
            patronSecondName: {
                type: String,
                default: ''
            },
            patronSecondPhone: {
                type: String,
                default: ''
            },
            attorneyFileArray: {
                type: Array,
                default: []
            },
            photoForPassportArray: {
                type: Array,
                default: []
            }
        },

        setup(props, { emit }) {
            const passportFilesInput = ref(null)
            const idNumberFilesInput = ref(null)
            const attorneyFilesInput = ref(null)
            const photoForPassportInput = ref(null)
            const currentImage = ref(null)
            const showImageViewer = ref(false)
            const currentPhotoType = ref(null)

            const endPasspotDate = computed({
                get() {
                    if (!props.passportEndDate.includes('T'))
                        return props.passportEndDate
                    return formateDate(props.passportEndDate)
                },
                set(value) {
                    emit('update:passportEndDate', value)
                }
            })

            const uploadPassportPhoto = () => {
                passportFilesInput.value.click()
            }

            const uploadIdNumberPhoto = () => {
                idNumberFilesInput.value.click()
            }

            const uploadAttorneyPhoto = () => {
                attorneyFilesInput.value.click()
            }

            const uploadPhotoForPassport = () => {
                photoForPassportInput.value.click()
            }

            const setImageToView = (image, type) => {
                currentImage.value = image
                currentPhotoType.value = type
                showImageViewer.value = true
                console.log(currentPhotoType.value)
            }

            return {
                uploadPassportPhoto,
                passportFilesInput,
                currentImage,
                showImageViewer,
                setImageToView,
                uploadIdNumberPhoto,
                idNumberFilesInput,
                currentPhotoType,
                attorneyFilesInput,
                uploadAttorneyPhoto,
                endPasspotDate,
                photoForPassportInput,
                uploadPhotoForPassport
            }
        },

        components: {
            DataInput,
            ImageViewer
        }
    }
</script>


<style scoped>


    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23ABD3EC" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

</style>