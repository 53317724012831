<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="w-[250px] h-[500px] bg-[#142552] rounded-[10px] border-[1px] border-[#303061]">
        <div>
            <div>
                <img 
                    @click="uploadPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="absolute left-[210px] top-[20px] cursor-pointer z-40"
                >
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="$emit('loadPhoto', $event)"
                />
            </div>
            <div class="flex justify-center h-[162px] w-full">
                <img :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="relative top-[10px] student-logo">
            </div>
        </div>

        <div class="bg-[#ABD3EC] w-full h-[74px] relative top-[10px]">
            <div class="text-[30px] font-semibold text-center">
                <div class="h-[45px]" v-if="name?.length > 0 || surname?.length > 0">
                    <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                        <span v-for="id in 100" class="mx-3">{{ name + ' ' + surname }}</span>
                    </marquee>
                    <span v-else>{{ name + ' ' + surname }}</span>
                </div>
                <span v-else class="opacity-25">{{ $t('studentBlock.studentCard.main.main_placeholder') }}</span>
            </div>
            <div class="flex relative justify-between top-[-5px] mx-[8px]">
                <div class="flex flex-col items-center justify-center">
                    <div class="text-[9px]">{{ $t('studentBlock.studentCard.main.group') }}</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="group?.length > 0">{{ group }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">{{ $t('studentBlock.studentCard.main.age') }}</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="age > 0">{{ age }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">{{ $t('studentBlock.studentCard.main.amplua') }}</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="studentAmplua?.length > 0">{{ studentAmplua }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">{{ $t('studentBlock.studentCard.main.number') }}</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="studentNumber?.length > 0">{{ studentNumber }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex  items-center justify-center font-normal">
                    <img src="@/assets/logo.svg" class="w-[30px]">
                    <div class="text-[#142552] mx-1 mb-[2px]">|</div>
                    <img :src="linqToFlag" class="w-[25px] ml-[5px]">
                </div>
            </div>
        </div>

        <div class="mx-[14px] relative  top-[27px]">
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="surname" @change="$emit('update:surname', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.main.surname_placeholder')" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.main.surname') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="name" @change="$emit('update:name', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.main.name_placeholder')" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.main.name') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input :value="patronymic" @change="$emit('update:patronymic', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" :placeholder="$t('studentBlock.studentCard.main.patronymic_placeholder')" type="text">
                </div>
                <div class="font-normal w-[50%] text-end text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.main.patronymic') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="formattedBirthDate" @change="formattedBirthDate = $event.target.value" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.main.birthday') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="phoneNumber" @change="$emit('update:phoneNumber', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="+38000000000" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.main.phone') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="email" @change="$emit('update:email', $event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="student@gmail.com" type="text">
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.main.email') }}
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input @focusin="nationalityInputActive = true" v-model="currentNationality" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Українець" type="text">
                    <div v-if="nationalityInputActive" :key="flagsKey" class="h-[100px] overflow-y-auto bg-[#142552] scrolltab absolute w-[235px] bottom-[25px] left-[-4px]">
                        <div v-for="flag in showFlags" @click="changeFlag(flag)" class="flex py-[3px] justify-between w-full text-[13px] hover:bg-[#182c63] cursor-pointer bg-[#142552] px-[4px]">
                            <div class="text-[#ABD3EC]">{{ flag }}</div>
                            <div><img :src="require(`@/assets/flags/${flag}.svg`)" class="w-[25px] ml-[5px]"></div>
                        </div>
                    </div>
                    <!-- <select :value="currentFlag" @change="changeFlag($event.target.value)" class="text-[#ABD3EC] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60">
                        <option v-for="flag in flagsArray" :value="flag" class="bg-[#142552]">{{ flag }} <img :src="linqToFlag" class="w-[25px] ml-[5px]"></option>
                    </select> -->
                </div>
                <div class="font-normal text-[#ABD3EC80] opacity-90 text-[11px]">
                    {{ $t('studentBlock.studentCard.main.nationality') }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import { uuid } from 'vue3-uuid'
    import DataInput from '../ui/DataInput.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'new-info-card',

        props: {
            surname: {
                type: String,
                default: '',
                required: true
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            patronymic: {
                type: String,
                default: '',
                required: true
            },
            birthDate: {
                type: String,
                default: '',
                required: true
            },
            phoneNumber: {
                type: String,
                default: '',
                required: true
            },
            email: {
                type: String,
                default: '',
                required: true
            },
            group: {
                type: String,
                default: '',
                required: true
            },
            studentPhoto: {
                type: [String, null],
                default: null,
                required: true
            },
            nationality: {
                type: String,
                default: '',
                required: true
            },
            studentNumber: {
                type: String,
                default: '',
                required: true
            },
            studentAmplua: {
                type: String,
                default: '',
                required: true
            }
        },

        setup(props, { emit }) {
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(props.studentPhoto)

            const flagsArray = ref(['AD', 'BH', 'CO', 'FM', 'ID', 'KY', 'MN', 'PE', 'SH', 'TR', 'AE', 'BI', 'CR', 'FR', 'IE', 'KZ', 'MO', 'PF', 'SI', 'TT', 'AF', 'BJ', 'CU', 'GA', 'IL', 'LA', 'MR', 'PG', 'SK', 'TW', 'AG', 'BM', 'CV', 'GB', 'IM', 'LB', 'MS', 'PH', 'SL', 'TZ', 'AI', 'BN', 'CY', 'GD', 'IN', 'LC', 'MT', 'PK', 'SM', 'UA', 'AL', 'BO', 'CZ', 'GE', 'IQ', 'LI', 'MU', 'PL', 'SN', 'UG', 'AM', 'BR', 'DE', 'GG', 'IR', 'LK', 'MV', 'PR', 'SO', 'US', 'AN', 'BS', 'DJ', 'GH', 'IS', 'LR', 'MW', 'PT', 'SR', 'UY', 'AO', 'BT', 'DK', 'GI', 'IT', 'LS', 'MX', 'PW', 'ST', 'UZ', 'AR', 'BW', 'DM', 'GM', 'JE', 'LT', 'MY', 'PY', 'SV', 'VC', 'AT', 'BY', 'DO', 'GN', 'JM', 'LU', 'MZ', 'QA', 'SY', 'VE', 'AU', 'BZ', 'DZ', 'GQ', 'JO', 'LV', 'NA', 'RO', 'SZ', 'VG', 'AW', 'CA', 'EC', 'GR', 'JP', 'LY', 'NE', 'RS', 'TC', 'VN', 'AX', 'CD', 'EE', 'GT', 'KE', 'MA', 'NG', 'TD', 'VU', 'AZ', 'CF', 'EG', 'GW', 'KG', 'MC', 'NI', 'RW', 'TG', 'WS', 'BA', 'CG', 'ER', 'GY', 'KH', 'MD', 'NL', 'SA', 'TH', 'YE', 'BB', 'CH', 'ES', 'HK', 'KM', 'ME', 'NO', 'SB', 'TJ', 'BD', 'CI', 'ET', 'HN', 'KN', 'MG', 'NP', 'SC', 'TL', 'BE', 'CL', 'FI', 'HR', 'KP', 'MK', 'NZ', 'SD', 'TM', 'BF', 'CM', 'FJ', 'HT', 'KR', 'ML', 'OM', 'SE', 'TN', 'BG', 'CN', 'FK', 'HU', 'KW', 'MM', 'PA', 'SG', 'TO'])
            const showFlags = ref([])
            const currentFlag = ref('UA')
            const linqToFlag = ref(require(`@/assets/flags/UA.svg`))

            const nationalityInputActive = ref(false)
            const flagsKey = ref(uuid.v4())

            const currentNationality = ref('')

            watch(currentNationality, () => {
                emit('update:nationality', currentNationality.value)
            })

            const changeFlag = (flag) => {
                currentFlag.value = flag
                linqToFlag.value = require(`@/assets/flags/${flag}.svg`)
                currentNationality.value = flag
                nationalityInputActive.value = false
                flagsKey.value = uuid.v4()
            }

            const filterFlagsByNationality = () => {
                const filteredFlags = props.nationality.length > 0 || props.nationality == 'UKR' ? flagsArray.value.filter(flag => flag.toLowerCase().includes(props.nationality.toLowerCase())) : flagsArray.value
                showFlags.value = filteredFlags
                flagsKey.value = uuid.v4()
            }

            const formattedBirthDate = computed({
                get() {
                    if (!props.birthDate.includes('T'))
                        return props.birthDate
                    return formateDate(props.birthDate)
                },
                set(value) {
                    emit('update:birthDate', value)
                }
            })

            const age = computed({
                get() {
                    return new Date(props.birthDate) < new Date() ? new Date().getFullYear() - new Date(props.birthDate).getFullYear() : '—'
                }
            }) 
            
            const uploadPhoto = () => {
                fileInput.value.click()
            }

            onMounted(() => {
                console.log(typeof photo.value)
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('@/assets/student_photo_placeholder.svg')
                }
                if (props.nationality.length > 0 && props.nationality !== 'UKR') {
                    currentFlag.value = props.nationality
                    linqToFlag.value = require(`@/assets/flags/${props.nationality}.svg`)
                } else {
                    currentFlag.value = 'UA'
                    linqToFlag.value = require(`@/assets/flags/UA.svg`)
                }
                currentNationality.value = props.nationality
                filterFlagsByNationality()
            })

            watch(() => props.nationality, (first, second) => {
                filterFlagsByNationality()
            })

            watch(() => props.studentPhoto, (first, second) => {
                photo.value = first
                inputValue.value = null
            })

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue,
                age,
                formattedBirthDate,
                flagsArray,
                currentFlag,
                linqToFlag,
                changeFlag,
                nationalityInputActive,
                showFlags,
                flagsKey,
                filterFlagsByNationality,
                currentNationality,
                changeFlag
            }
        },

        components: {
            DataInput
        },
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23ABD3EC" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }


    .scrolltab::-webkit-scrollbar {
        width: 3px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #a6a4a4;
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }
</style>