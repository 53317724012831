<template>
    <svg :width="fullWidth + 45" :height="height">
        <rect :key="index" :x="0" :y="0" :width="percentWidth" :height="height" fill="#41BF74" />
        <text :key="index" :x="percentWidth + 5" :y="height / 2 + 6" width="100px" text-anchor="start" fill="#41BF74">{{ value }}</text>

    </svg>
</template>

<script>
export default {
    props: {
        percent: {
            type: Number,
            required: true,
            default: 100
        },
        value: {
            type: Number,
            required: true,
            default: 100
        },
        fullWidth: {
            type: Number,
            required: true,
            default: 200
        },
        height: {
            type: Number,
            default: 17,
        },
    },
    computed: {
        percentWidth() {
            return this.value == 0 ? 1 : (this.fullWidth / 100) * this.percent;
        },
    },
};
</script>